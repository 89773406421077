import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';
import { getComponent } from './getComponent';
import routes from "./routes.json"
import {Toaster} from "react-hot-toast";

const App = () => {
  // STATE INITIALLIZATION
  const darkModes = useDarkMode(false);
  
  const renderRoutes = (routes) => {
    return routes.map((route, index) => {
      const { path, element, props, nestedRoutes } = route;
      const Component = getComponent(element);

      return (
        <Route
          key={`${index}`}
          path={path}
          element={<Component {...props} darkMode={darkModes} />}
        >
          {nestedRoutes && renderRoutes(nestedRoutes)}
        </Route>
      );
    });
  };

  return (
    <>
     <Toaster
            position="bottom-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              style: { boxShadow: "none", border: "1px solid #bdb5b5" },
              success: { duration: 3000 }
            }} />
      <BrowserRouter>
        <Routes>{renderRoutes(routes)}</Routes>
      </BrowserRouter>
    </>
  )
}

export default App;