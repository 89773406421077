import React, { useState, useEffect, useRef } from 'react';
import { Toaster, toast } from "react-hot-toast";
import AvatarComponent from "../../Components/NextUi/Avatar";
import ButtonComponent from "../../Components/NextUi/Button";
import DangerZone from "../../Components/BasicComponents/DangerZone";
import DeleteModal from "../../Components/BasicComponents/DeleteModal";
import { Input, Textarea, useDisclosure, Button } from '@nextui-org/react';
import { colorPicker, avatarName } from "../../Controllers/basicControllers";
import { useNavigate } from "react-router-dom";
import {validateForm} from "../../utils/rolloutValidation";
import { UpdateData, DeleteSingleAttribute, uploadFile } from "../../Controllers/strapiController";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const initialErrorState = { name: '', description: '' };

const AppSettings = ({ application, darkMode }) => {

  const [createForm, setCreateForm] = useState();
  const [formError, setFormError] = useState(initialErrorState);
  const [validate, setValidate] = useState(false);
  const { isOpen: isDelete, onOpen: onDelete, onOpenChange: onOpenDelete } = useDisclosure();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  
  // FILE UPLOAD REFERENCE REF
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };


  const commonProps = {
    isRequired: true,
    labelPlacement: 'outside',
    type: 'text',
    variant: 'flat',
    size: 'md',
    placeholder: ' '
  };

  useEffect(() => {
    if (application) {
        setCreateForm({
            name: application?.attributes?.Name,
            description: application?.attributes?.Description,
        })
    }
}, [application])

  // HANDLE ONCHANGE EVENT
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateForm((prev) => ({ ...prev, [name]: value }));
  }

  const UploadFileHandler = async (field, file) => {
    const response = await uploadFile(file);
    if (response) {
      const URLresponse = response && response[0];
      setCreateForm({
        ...createForm,
        [field]: URLresponse,
      });
    }
  };

  const submitHandler = async (onClose) => {

    const { isValid, errors } = validateForm(createForm);
    setFormError(errors);
    const payload = {
      Name: createForm?.name,
      Description: createForm?.description,
    }

    if (isValid) {
      setValidate(false);
      const response = await UpdateData('applications', application?.id, payload);
      if (response?.data) {
        CustomToast("application updated successfully.")
        // setReload(response);
      } else if (response.error) {
        CustomToast("Something went wrong. Not able to create app now. please try after some time.")
      }
    } else {
      setValidate(true);
    }
  }
  const DeleteHandler = async () => {
    const response = await DeleteSingleAttribute("applications", application?.id)
    if (response) {
      navigate("/application")
    }
  }

  const appLogo=application?.attributes?.Logo?.data!==null?`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${application?.attributes?.Logo?.data?.attributes?.url}`:"";

  return (
    <div className='flex flex-col gap-8 pt-8'>
      <Toaster
        position="bottom-right"
        reverseOrder={false}
      />
      <div className='flex flex-row gap-4 items-center'>
        <AvatarComponent radius="full" size='md' src={appLogo} color={colorPicker(application?.id)} name={avatarName(createForm?.name)} className="" />
        <input
            type='file'
            accept={``}
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => {
              const files = e.target.files;
              if (files && files[0]) {
                const file = files[0];
                UploadFileHandler("logo", file)
              }
            }}
          />
          <div className='flex flex-col gap-2 '>
            <div className='flex flex-row gap-2 items-center'>
              <Button variant='bordered' size='sm' onClick={handleButtonClick}>
                Select file
              </Button>
             {appLogo&& <p className='text-sm text-default-400'>remove</p>}
            </div>
          </div>
          </div>
      <div className='flex flex-row gap-4 items-center'>
        <Input className="w-full" {...commonProps} value={createForm?.name} label='application Name' name="name" onChange={handleChange} errorMessage={validate && createForm.name === '' && formError.name} isInvalid={validate && createForm.name === '' && formError.name !== null} />
      </div>
      <Textarea  {...commonProps} label='Description' value={createForm?.description} name='description' onChange={handleChange} errorMessage={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description} isInvalid={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description !== null} />
      <div className='flex justify-end'>
        <ButtonComponent title="Update" size='sm' color={colorPicker(application?.id)} variant='faded' func={() => { submitHandler() }} />
      </div>
      <div>
        <DangerZone onDeleteOpen={onDelete} title="application" />
      </div>
      <DeleteModal
        isOpen={isDelete}
        onOpenChange={onOpenDelete}
        Collectionname={application?.attributes?.Name}
        setLoader={""}
        DeleteHandler={DeleteHandler} />
    </div>
  )
}

export default AppSettings;