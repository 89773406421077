import React from 'react'
import { helix } from 'ldrs'

const Loading = ({ darkMode }) => {

  helix.register()

  return (
    <div className={`absolute z-[100]  inset-0 h-screen w-screen bg-slate-50`}>
      <div className={`flex items-center justify-center h-full backdrop-opacity-10 backdrop-invert bg-white`}>
        <l-helix
          size="45"
          speed="2.5"
          color= "black"
        ></l-helix>
      </div>
    </div>
  )
}

export default Loading