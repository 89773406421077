import React from 'react';
import {Image} from "@nextui-org/react";
import hayalogo from "../../Images/haya-logo.png"

const SubmissionPage = () => {
  return (
    <div className="z-50 bg-background absolute top-0 left-0 right-0 h-screen  flex h-screen items-center justify-center">
    <main className='mx-auto w-full flex flex-col items-center justify-center'>
      <div>
        <Image width={40} alt="NextUI" src={hayalogo} />
        <h1 className='mb-1.5 mt-8 sm:text-left text-2xl tracking-[-0.16px] text-slate-12 font-bold '>
        Your form response has been received.
        </h1>
        <p className='mt-8' >Thank you for your submission!</p>
        <p></p>
      </div>
    </main>
  </div>
  )
}

export default SubmissionPage