import React from 'react';
import {Tabs} from "@nextui-org/react";

const TabsComponent = ({tabs,tabsComponent,variant,color}) => {
  return (
    <Tabs aria-label="Dynamic tabs" items={tabs} variant={variant} color={color}>
     {tabsComponent()}
    </Tabs>
  )
}

export default TabsComponent;