import React from 'react'
import { waveform } from 'ldrs'

const FunctionLoader = ({ darkMode }) => {
    waveform.register()

    return (
        <div>
            <div className={`flex pt-24 items-center justify-center h-full backdrop-opacity-10 backdrop-invert bg-white `}>
                <l-waveform
                    size="35"
                    speed="1"
                    color="grey"
                ></l-waveform>
            </div>
        </div>
    )
}

export default FunctionLoader;