import React, { useState, useEffect } from 'react'
import Titlecomponent from '../../Components/BasicComponents/Titlecomponent';
import ButtonComponent from "../../Components/NextUi/Button";
import TableComponent from "../../Components/NextUi/Table";
import CreateRollout from './CreateRollout';
import { useDisclosure, TableRow, TableCell, Tooltip } from "@nextui-org/react";
import { CommonCollectionFilterApi, userLoginDetails, DeleteSingleAttribute } from "../../Controllers/strapiController";
import { useSelector } from 'react-redux';
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import { encryptedText, StringLimit, titleCreater } from '../../Controllers/basicControllers';
import { iconRender } from "../../Components/Navbar/items";
import DeleteModal from '../../Components/BasicComponents/DeleteModal';
import PaginationComponent from '../../Components/NextUi/Pagination';
import Loading from '../../Components/ErrorHandler/Loading';
import { useNavigate } from "react-router-dom";
import { appData } from "../../Redux/actions";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const Rollout = ({ darkMode }) => {
  const [reload, setReloader] = useState();
  const headerComponent = ["Name", "Description", ""]
  const description = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea ullam veniam voluptatem eaque cum provident voluptas dicta eum nulla rem!";
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { isOpen: isDelete, onOpen: onDelete, onOpenChange: onDeleteChange } = useDisclosure();
  const [rollout, setRollout] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [loader, setLoader] = useState(true);
  const userId = userLoginDetails()?.userId;
  const [deleteData, setDeleteData] = useState();
  const navigate = useNavigate();

  // FILTER URL TO FILTER WITH APPLICATIONS
  const appInfo = useSelector(appData);
  const applications = appInfo?.map((val, index) => `filters[$or][${index + 1}][app_id]=${val?.id}`)?.join('&');

  useEffect(() => {
    const url = `filters[$or][0][author][id][$eq]=${userId}&${applications}&[populate][author][on]&sort[0]=createdAt:desc`;
    CommonCollectionFilterApi("rollouts", url)
      .then((data) => {
        if (data?.error) {
          setLoader(false);
          if (data?.error?.status === 404) { setNotFound(true) }
          else if (data?.error?.status === 403) { setForbidden(true) }
          else if (data?.error?.status === 500) { setServerIssue(true) }
          else if (data?.error?.status === 401) { setAuthorized(true) }
          else {
            CustomToast("Something went wrong with collections, please try after some time.")
          }
        }
        else if (data?.data) {
          setRollout(data?.data);
          setLoader(false);
        }
      })
      .catch((error) => { setServerIssue(true); setLoader(false); })
  }, [userId, reload, appInfo])

  const tableBodyComponet = () => {
    return currentRecords?.map((data, index) => {
      return <TableRow key={`${index}`} onClick={() => NavigateHandler(data)} className='cursor-pointer'>
        <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
        <TableCell>{StringLimit(data?.attributes?.Description, 150)}</TableCell>
        <TableCell >
          <div onClick={(e) => deleteOnchange(data, e)}>
            <Tooltip content='Delete' placement='left'>
              {iconRender("https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    })
  }

  const deleteOnchange = (data, e) => {
    e.stopPropagation();
    onDelete();
    setDeleteData(data);
  }

  const DeleteHandler = async () => {
    const response = await DeleteSingleAttribute("rollouts", deleteData.id)
    if (response) {
      setReloader(response);
      CustomToast("Rollout deleted successfully.")
    }
  }

  const NavigateHandler = (data) => {
    const id = encryptedText(`${data.id}`);
    navigate(`${id}`);
  }

  const recordsPerPage = 10;

  // Calculate indexes for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = rollout && rollout.slice(indexOfFirstRecord, indexOfLastRecord);

  if (notFound) { return <NotFound /> }
  if (serverIssue) { return <ServerIssue /> }
  if (forbidden) { return <Forbbiden /> }
  if (authorized) { return <UnAuthorized /> }
  if (loader) { return <Loading darkMode={darkMode} /> }

  return (
    <div>
      <Titlecomponent title="Rollout" description={description} />
      <div className='flex flex-row justify-end'>
        <ButtonComponent title="+ Create a new rollout" size='sm' color='danger' variant="default" func={() => onOpen()} />
      </div>
      <div className='pt-8'>
        <TableComponent headerComponent={headerComponent} bodyComponent={tableBodyComponet} emptyContent={"No rollout created yet. Click on Create new rollout to create your first rollout"} />
      </div>
      <CreateRollout isOpen={isOpen} onOpenChange={onOpenChange} darkMode={darkMode} setReloader={setReloader} />
      {rollout?.length > 10 && <div className='flex justify-end pt-8'>
        <PaginationComponent
          color={'secondary'}
          totalPage={rollout}
          currentRecords={currentRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          recordsPerPage={recordsPerPage}
        />
      </div>}
      <DeleteModal
        isOpen={isDelete}
        onOpenChange={onDeleteChange}
        Collectionname={deleteData?.attributes?.Name}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler} />
    </div>
  )
}

export default Rollout;