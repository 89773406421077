import React from 'react';
import { Switch } from "@nextui-org/react";

const Toggle = ({title,isSelected,onValueChange}) => {
    return (
        <div className='flex flex-col gap-2 w-1/2'>
            <p className='text-sm pt-4'>{title}</p>
            <Switch size='sm' color='secondary' isSelected={isSelected} onValueChange={onValueChange}>
                {isSelected?"On":"Off"}
            </Switch>
        </div>
    )
}

export default Toggle