import React from 'react';
import Response from '../Responses/Response';
import SurveyTable from '../Servey/SurveyTable';
import EditRollout from './EditRollout';
import RolloutChannels from './RolloutChannels';

const TabsJson = ({ darkMode, rollout, setReload }) => {


    let tabs = [
        {
            id: "rollouts",
            label: "Rollouts",
            content: "Rollouts"
        },
        {
            id: "channels",
            label: "Channels",
            content: <RolloutChannels darkMode={darkMode} rollout={rollout} />
        },
        {
            id: "surveys",
            label: "Surveys",
            content: <div className='pt-8'><SurveyTable darkMode={darkMode} rollout={rollout} /></div>
        },
        {
            id: "responses",
            label: "Responses",
            content: <Response darkMode={darkMode} rollout={rollout} />
        },
        {
            id: "insights",
            label: "Insights",
            content: "Insights"
        },
        {
            id: "settings",
            label: "Settings",
            content: <EditRollout darkMode={darkMode} rollout={rollout} setReload={setReload} />
        }
    ];
    return tabs
}

export default TabsJson