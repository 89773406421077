import React, { useState, useEffect } from "react";
import CardComponent from "../../Components/NextUi/Card";
import ButtonComponent from "../../Components/NextUi/Button";
import {
  useDisclosure,
  ModalHeader,
  ModalBody,
  Tooltip,
  Input,
  Chip,
} from "@nextui-org/react";
import { colorPicker, encryptedText } from "../../Controllers/basicControllers";
import { iconRender } from "../../Components/Navbar/items";
import ModalPopup from "../../Components/NextUi/ModalPopup";
import { Toaster } from "react-hot-toast";
import { InviteUser } from "../../Controllers/strapiController";
import CustomToast from "../../Components/BasicComponents/CustomToast";

const RolloutChannels = ({ darkMode, rollout }) => {
  // STATE INITIALIZATION
  const [tagInput, setTagInput] = useState("");
  const [emails, setEmails] = useState([]);
  const [displayedItems, setDisplayedItems] = useState();
  const [showAll, setShowAll] = useState(false);
  const [validatetags, setValidatetags] = useState("");
  const text = tagInput.trim().toLowerCase();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isEmailOpen,
    onOpen: onEmailOpen,
    onOpenChange: onOpenEmailChange,
  } = useDisclosure();
  const surveyForm = rollout?.attributes?.survey_schema?.data?.id;
  const surveyFormName =
    rollout?.attributes?.survey_schema?.data?.attributes?.Name;

  const handleShowMore = () => {
    setDisplayedItems(emails);
    setShowAll(true);
  };

  const handleTagRemove = (tagToRemove) => {
    setEmails(emails.filter((tag) => tag !== tagToRemove));
  };

  const handleShowLess = () => {
    emails && setDisplayedItems(emails.slice(0, 5));
    setShowAll(false);
  };

  const validForm = () => {
    var isValid = true;

    if (text !== "" && emails.includes(text)) {
      isValid = false;
      setValidatetags("Entered email already exists.");
    }

    if (text !== "" && !emailRegex.test(text)) {
      isValid = false;
      setValidatetags("Enter a valid email.");
    }

    return isValid;
  };

  const handleKeyPress = (e) => {
    const validData = validForm();
    if (tagInput.trim() !== "" && e.key === "Enter") {
      if (validData) {
        setEmails((previous) => [text, ...previous]);
        setValidatetags("");
        setTagInput("");
      }
    }
  };

  useEffect(() => {
    if (showAll) {
      handleShowMore();
    } else {
      setDisplayedItems(emails.slice(0, 5));
    }
  }, [emails]);

  const renderTags = () => {
    return displayedItems?.map((email, index) => (
      <>
        {" "}
        <Chip
          key={`${index}`}
          className=""
          size="sm"
          color={colorPicker(index)}
          variant="flat"
          onClose={() => handleTagRemove(email)}
        >
          {email}
        </Chip>
      </>
    ));
  };

  const commonProps = {
    isRequired: true,
    labelPlacement: "outside",
    type: "text",
    variant: "faded",
    size: "md",
    placeholder: " ",
  };

  const jsonSchema = [
    {
      id: 1,
      title: "Send via API",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit.ipsum dolor sit amet consectetur adipisicing elit. Tempora, fugit,Lorem, ipsum dolor sit ame Tempora, fugit,Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora, fugit",
      url: "https://cdn.lordicon.com/trkmrggx.json",
      urlId: "ViaApi",
      button: "View API",
      func: onOpen,
    },
    {
      id: 2,
      title: "Send Email",
      description:
        "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora, fugit,Lorem, ipsum dolor sit ameipsum dolor sit amet consectetur adipisicing elit. Tempora, fugit,Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora, fugit",
      url: "https://cdn.lordicon.com/nzixoeyk.json",
      urlId: "email",
      button: "Send Email",
      func: onEmailOpen,
    },
  ];

  // CURL COMMAND
  const code = `curl --location '${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send' \\
  --header 'Content-Type: application/json' \\
  --header 'authorization: API Key xx**xx' \\
  --data '{
      "metric_id": "${surveyForm}",
      "response": {
      "to":"<Enter Your email>",
      "urlText":"${surveyFormName}"
      }
  }'`;

  // FUNCTION TO HANDLE THE CLICK AND COPY THE CURL COMMAND
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        CustomToast("Curl command copied to clipboard!");
      })
      .catch((err) => {
        CustomToast("Failed to copy text: ", err);
      });
  };

  const submitHandler = async (onClose) => {
    const invitations = (emails || []).map(async (data) => {
      const surveyName = rollout?.attributes?.survey_schema?.data;
      const id = encryptedText(`${surveyName?.id}`);
      const email = encryptedText(`${data}`);
      const now = new Date();
      const timeStamp = Math.floor(now / 1000);
      const encryptedTime = encryptedText(`${timeStamp}`);

      const url = `${process.env.REACT_APP_IP_ADDRESS}/survey/${id}/${email}/${encryptedTime}`;

      const payload = {
        to: `${data}`,
        urlText: `${surveyName?.attributes?.Name}`,
        path: `${url}`,
      };

      const r = await InviteUser(payload);
      return r;
    });
    const response = await Promise.all(invitations);
    setEmails([]);
    if (response) {
      CustomToast("Survey sent successfully.");
      onClose();
    }
  };

  const emailCard = () => {
    return (
      <div className="flex flex-wrap gap-2">
        {renderTags()}
        {!showAll ? (
          <>
            {emails && emails.length > 5 ? (
              <Chip className="" size="sm" onClick={handleShowMore}>
                Show More
              </Chip>
            ) : (
              ""
            )}
          </>
        ) : (
          <Chip className="" size="sm" onClick={handleShowLess}>
            Show less
          </Chip>
        )}
      </div>
    );
  };

  const cardBodyData = () => {
    return (
      <div className="text-sm">
        <div
          className="flex flex-row justify-end w-full cursor-pointer"
          onClick={handleCopyClick}
        >
          <Tooltip content="Copy" delay={0} closeDelay={0}>
            {iconRender("https://cdn.lordicon.com/vdjwmfqs.json", "Copy")}
          </Tooltip>
        </div>
        {code?.split("\\")?.map((data) => (
          <pre className="text-xs">{data}</pre>
        ))}
      </div>
    );
  };

  const ModalBodyData = () => {
    return (
      <>
        <ModalHeader>Send Via API</ModalHeader>
        <ModalBody>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
          distinctio a facilis, suscipit ratione itaque repudiandae neque quasi
          doloremque perspiciatis!
          <div className="pt-4">
            <CardComponent
              shadow="none"
              radius="sm"
              className={"border border-divider bg-default-200"}
              cardBody={cardBodyData}
              cardHeader={""}
            />
          </div>
        </ModalBody>
      </>
    );
  };

  const ModalEmailBodyData = () => {
    return (
      <>
        <ModalHeader>Send Email</ModalHeader>
        <ModalBody>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga
          distinctio a facilis, suscipit ratione itaque repudiandae neque quasi
          doloremque perspiciatis!
          <div className="pt-4 flex flex-col">
            <Input
              label="Enter user email"
              {...commonProps}
              onChange={(e) => setTagInput(e.target.value)}
              value={tagInput}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleKeyPress(e);
                }
              }}
            />
            <p className="pt-2 text-xs text-red-400">
              {tagInput !== "" && validatetags !== ""
                ? emails.includes(text) ||
                  validatetags ||
                  !emailRegex.test(text)
                  ? validatetags
                  : ""
                : ""}
            </p>
          </div>
          <CardComponent
            shadow="none"
            radius="sm"
            className={"border border-divider bg-default-100 min-h-[150px]"}
            cardBody={emailCard}
            cardHeader={""}
          />
        </ModalBody>
      </>
    );
  };

  const footerCreate = (onClose) => {
    return (
      <>
        <ButtonComponent title="Cancel" size="sm" variant="" color="none" />
        <ButtonComponent
          isDisabled={emails?.length > 0 ? false : true}
          title="Send"
          size="sm"
          variant=""
          color="danger"
          func={() => submitHandler(onClose)}
        />
      </>
    );
  };

  const cardHeader = (data) => {
    return (
      <div className="flex flex-row gap-2 items-center">
        {iconRender(data?.url, data?.urlId)}
        <p className="text-lg">{data?.title}</p>
      </div>
    );
  };

  const cardBody = (data) => {
    return (
      <div className="flex flex-row justify-between gap-8">
        <div className="text-sm">{data?.description}</div>
        <div>
          <ButtonComponent
            title={data?.title}
            size="sm"
            color={colorPicker(data?.id)}
            func={data?.func}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <div className="pt-8 flex flex-col gap-8">
        {jsonSchema?.map((data) => {
          return (
            <CardComponent
              shadow="none"
              radius="sm"
              className={"border border-divider"}
              cardBody={() => cardBody(data)}
              cardHeader={() => cardHeader(data)}
            />
          );
        })}
      </div>
      <ModalPopup
        size="md"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        ModalBodyData={ModalBodyData}
        footer={""}
        scrollBehavior="inside"
      />
      <ModalPopup
        size="lg"
        isOpen={isEmailOpen}
        onOpenChange={onOpenEmailChange}
        ModalBodyData={ModalEmailBodyData}
        footer={footerCreate}
        scrollBehavior="inside"
      />
    </>
  );
};

export default RolloutChannels;
