import { Modal, ModalContent, ModalFooter } from '@nextui-org/modal';
import React from 'react';


const ModalPopup = (props) => {

  return (
    <div className='h-full z-100 transform-null'>
      <Modal
        size={props.size}
        scrollBehavior={props.scrollBehavior && props.scrollBehavior}
        isOpen={props.isOpen}
        onOpenChange={props.onOpenChange}
        placement="center"
        radius='sm'
        style={{ marginTop: "0", marginBottom: "0" }}
        className='min-h-[400px] py-4 px-4 my-0'>
        <ModalContent>
          {(onClose) => (
            <>
              {props.ModalBodyData && props.ModalBodyData()}
              <ModalFooter className={props.className}>
                {props.footer && props.footer(onClose)}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  )
}

export default ModalPopup;