import SideNavbar from './Components/Navbar/SideNavbar';
import Applications from './pages/Applications/Applications';
import Overview from './pages/Overview/Overview';
import Channels from './pages/Channels/Channels';
import ServeyBuilder from './pages/Servey/ServeyBuilder';
import Signin from './pages/Administration/Signin';
import NotFound from "./Components/ErrorHandler/NotFound";
import CreateSurvey from './pages/Servey/CreateSurvey';
import UserSettings from "./pages/Settings/userSettings";
import SurveyForm from './pages/SurveyForm/SurveyForm';
import SingleApplication from './pages/Applications/SingleApplication';
import Rollout from './pages/Rollout/Rollout';
import Insights from './pages/Insights/Insights';
import SingleRollout from './pages/Rollout/SingleRollout';
import Keys from './pages/Keys/Keys';
import SignUp from './pages/Administration/SignUp';
import OTP from './pages/Administration/OTP';
import SingleKeys from './pages/Keys/SingleKeys';
const components = {
    NotFound,
    Signin,
    SignUp,
    OTP,
    SurveyForm,
    SideNavbar,
    Overview,
    Applications,
    SingleApplication,
    ServeyBuilder,
    CreateSurvey,
    Channels,
    Rollout,
    SingleRollout,
    Insights,
    Keys,
    SingleKeys,
    UserSettings
}

export const getComponent = (name, darkMode) => components[name] || NotFound;