import React from 'react'
import Titlecomponent from '../../Components/BasicComponents/Titlecomponent';
import ButtonComponent from '../../Components/NextUi/Button';
import SurveyTable from './SurveyTable';
import { useNavigate } from "react-router-dom";

const ServeyBuilder = ({ darkMode, reload }) => {
  const navigate = useNavigate();
  const description = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, obcaecati. Dolor, est autem sequi iste fuga ex delectus eligendi dolore.Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, obcaecati. Dolor, est autem sequi iste fuga ex delectus eligendi dolore.';

  const navigateHandler = () => {
    navigate('/survey/create');
  }

  return (
    <div>
      <Titlecomponent title="Survey" description={description} />
      <div className='flex justify-end'>
        <ButtonComponent title='+ Create new survey builder' size='sm' varient='flat' color='danger' func={navigateHandler} />
      </div>
      <div className='pt-8'>
        <SurveyTable serverLoad={reload} darkMode={darkMode}  url={false}/>
      </div>
    </div>
  )
}

export default ServeyBuilder;