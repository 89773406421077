import React, { useState, useEffect, useMemo } from "react";
import TableComponent from "../../Components/NextUi/Table";
import {
  CommonCollectionFilterApi,
  DeleteSingleAttribute,
  userLoginDetails,
} from "../../Controllers/strapiController";
import NotFound from "../../Components/ErrorHandler/NotFound";
import ServerIssue from "../../Components/ErrorHandler/ServerIssue";
import Forbbiden from "../../Components/ErrorHandler/Forbbiden";
import UnAuthorized from "../../Components/ErrorHandler/UnAuthorized";
import Loading from "../../Components/ErrorHandler/Loading";
import { TableRow, TableCell, Tooltip, useDisclosure } from "@nextui-org/react";
import {
  encryptedText,
  StringLimit,
  titleCreater,
} from "../../Controllers/basicControllers";
import { iconRender } from "../../Components/Navbar/items";
import DeleteModal from "../../Components/BasicComponents/DeleteModal";
import PaginationComponent from "../../Components/NextUi/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomToast from "../../Components/BasicComponents/CustomToast";
import { appData } from "../../Redux/actions";

const SurveyTable = ({ darkMode, serverLoad, rollout }) => {
  // STATE INITIALIZATION
  const tableHeader = ["Name", "Description", " "];
  const [surveyData, setSurveyData] = useState([]);
  const userId = userLoginDetails()?.userId;
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [loader, setLoader] = useState(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [deleteData, setDeleteData] = useState();
  const [reload, setReload] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  // FILTER URL TO FILTER WITH APPLICATIONS
  const appInfo = useSelector(appData);
  const applications = appInfo
    ?.map(
      (val, index) =>
        `filters[$or][${index + 1}][application][id][$eq]=${val?.id}`
    )
    ?.join("&");

  // API RESPONSE RENDERING
  useEffect(() => {
    if (rollout) {
      setSurveyData([rollout?.attributes?.survey_schema?.data]);
      setLoader(false);
    } else {
      const url1 = `filters[$or][0][author][id][$eq]=${userId}&${applications}&sort[0]=createdAt:desc&[populate][author][on]`;

      CommonCollectionFilterApi("survey-schemas", url1)
        .then((data) => {
          if (data?.error) {
            if (data?.error?.status === 404) {
              setNotFound(true);
            } else if (data?.error?.status === 403) {
              setForbidden(true);
            } else if (data?.error?.status === 500) {
              setServerIssue(true);
            } else if (data?.error?.status === 401) {
              setAuthorized(true);
            } else {
              CustomToast(
                "Something went wrong with collections, please try after some time."
              );
              setLoader(false);
            }
          } else if (data?.data?.length > 0) {
            setSurveyData(data.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          CustomToast(
            "Something went wrong with collections, please try after some time."
          );
          setLoader(false);
        });
    }
  }, [reload, userId, appInfo]);

  const deleteOnchange = (data, e) => {
    e.stopPropagation();
    onOpen();
    setDeleteData(data);
  };

  const DeleteHandler = async () => {
    const response = await DeleteSingleAttribute(
      "survey-schemas",
      deleteData.id
    );
    if (response) {
      setReload(response);
    }
  };

  const NavigateHandler = (data) => {
    const id = encryptedText(`${data.id}`);
    navigate(`/survey/${id}`);
  };

  const recordsPerPage = 10;

  // Calculate indexes for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    surveyData && surveyData?.slice(indexOfFirstRecord, indexOfLastRecord);

  const tableBodyComponet = () => {
    return currentRecords?.map((data, index) => {
      return (
        <TableRow
          key={index}
          onClick={() => NavigateHandler(data)}
          className="cursor-pointer"
        >
          <TableCell>{titleCreater(data?.attributes?.Name)}</TableCell>
          <TableCell>
            {StringLimit(data?.attributes?.Description, 150)}
          </TableCell>
          <TableCell>
            <div onClick={(e) => deleteOnchange(data, e)}>
              <Tooltip content="Delete" placement="left">
                {iconRender("https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
              </Tooltip>
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  if (notFound) {
    return <NotFound />;
  }
  if (serverIssue) {
    return <ServerIssue />;
  }
  if (forbidden) {
    return <Forbbiden />;
  }
  if (authorized) {
    return <UnAuthorized />;
  }
  if (loader) {
    return <Loading darkMode={darkMode} />;
  }

  return (
    <div>
      <div className="max-h-[410px]">
        <TableComponent
          headerComponent={tableHeader}
          bodyComponent={tableBodyComponet}
          emptyContent={
            "Survey is empty, Click on create new survey to create your first survey."
          }
        />
      </div>
      {surveyData?.length > 10 && (
        <div className="flex justify-end pt-8">
          <PaginationComponent
            color={"secondary"}
            totalPage={surveyData}
            currentRecords={currentRecords}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            recordsPerPage={recordsPerPage}
          />
        </div>
      )}
      <DeleteModal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        Collectionname={deleteData?.attributes?.Name}
        setLoader={setLoader}
        DeleteHandler={DeleteHandler}
      />
    </div>
  );
};

export default SurveyTable;
