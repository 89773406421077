import React, { useEffect, useState } from 'react'
import { Avatar, Button, Input, Chip, Tooltip } from '@nextui-org/react'
import { sendInvite, UpdateData } from '../../Controllers/strapiController';
import SearchIcon from '../../Images/Search';
import CryptoJS from "crypto-js";
import AvatarComponent from "../../Components/NextUi/Avatar";
import { avatarName, colorPicker, encryptedText, iconRenders } from '../../Controllers/basicControllers'
import { toast } from "react-hot-toast";

const ApplicationUsers = ({ application, setReload, authorization }) => {
    // STATE INITIALIZATION
    const [searchResults, setSearchResults] = useState(null);
    const [textValue, setTextValue] = useState('');
    const [EmailValidate, setEmailValidate] = useState('');
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [response, setResponse] = useState();

    const users = application?.attributes?.users_permissions_users?.data;

    // EVENT CHANGE HANDLER
    const HandleChange = (event) => {
        const TextValue = event.target.value;
        setTextValue(TextValue);
        const Filterdata = TextValue !== '' ? users && users?.filter((data) => data && data?.attributes?.email?.toLowerCase()?.includes(TextValue?.toLowerCase())) : users
        setSearchResults(Filterdata);
    }

    useEffect(() => { setSearchResults(users) }, [users])

    // ONCHANGE VALIDATION
    useEffect(() => {
        const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (textValue !== '' && searchResults.length === 0) {
            if (!EmailRegex.test(textValue)) {
                setEmailValidate("Please enter a valid email.")
            } else {
                setEmailValidate(null)
            }
        }
    }, [textValue, searchResults])

    // TAGS TO CREATE
    const handleKeyPress = (e) => {
        if (tagInput.trim() !== '' && e.key === "Enter") {
            if (!tags.includes(Text)) {
                setTags((previous) => [...previous, Text]);
                setTagInput('');
            }
        }
    }

    // USER SECTION FORMAT
    const userDisplay = (data) => {
        return <div className='flex flex-row items-center gap-4  w-full'>
            <Avatar src='' size='sm' color={colorPicker(data?.id)} name={avatarName(data?.attributes?.email)} />
            <div className='flex flex-col'>
                <p className=''>{data?.attributes?.email?.split('@')[0]}</p>
                <p className='text-slate-400'>{data?.attributes?.username}</p>
            </div>
        </div>
    }

    // DELETE HANDLER TO DELETE COLLECTION
    const DeleteHandler = async (id) => {

        const payload = {
            users_permissions_users: { disconnect: [{ id: parseInt(id) }], connect: [] }
        }
        const response = await UpdateData("applications", parseInt(application?.id), payload);
        if (response) {
            setResponse(response);
            setReload(response)
        }
    }

    // INVITING THE MEMBERS TO SPACE. INVITE BUTTON
    const InviteToSpaceHandler = async () => {
        const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
        const inputString = textValue;
        const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
        const AESHash = encrypted.toString();
        const url = `${process.env.REACT_APP_IP_ADDRESS}/applications/${encryptedText(`${application?.id}`)}/?${btoa(AESHash)}`
        const payload = {
            to: `${textValue}`,
            urlText: `Invitation from Haya.`,
            invitationLink: `${url}`
        };
        sendInvite(payload);
        setTextValue('');
        setResponse(response);
        return response
    }

    // RETURN UI
    return (
        <div>
            <div className="flex flex-col  gap-1">
                <h1 className="pt-8 pb-8 text-xl font-medium">Manage users</h1>
                <div className='pt-2 pb-8'>
                    <Input
                        classNames=""
                        onChange={HandleChange}
                        value={textValue}
                        onKeyPress={(event) => { if (event.key === 'Enter') { handleKeyPress(event) } }}
                        placeholder='Search by user content...'
                        startContent={<SearchIcon className="text-large text-default-400 pointer-events-none flex-shrink-0" />}
                    />
                </div>
            </div>
            <div className='gap-0'>
                <div>
                    <div className='divide-y divide-divider'>
                        {searchResults && searchResults.length > 0 ? <>
                            {searchResults?.map((data, index) => {
                                return <div key={`${index+1}`} className='flex flex-row justify-between items-center py-2'>
                                    {userDisplay(data)}
                                    <div className='w-full  text-center'>
                                        <Chip size='sm' color="success" variant='flat'>Invited</Chip>
                                    </div>
                                    <p className='pr-4 cursor-not-allowed'>
                                        <Tooltip isDisabled={authorization ? false : true} content="Remove" placement='left' className='cursor-pointer' onClick={() => DeleteHandler(data?.id)}>
                                            {iconRenders("https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
                                        </Tooltip>
                                    </p>
                                </div>
                            })}
                        </>
                            :
                            <>{textValue !== "" ? <>{EmailValidate !== null ?
                                <p className='text-red-500'>{EmailValidate}</p> :
                                <>{authorization ? <div className='flex flex-row justify-between items-center'>
                                    <div className='flex flex-row items-center gap-4'>
                                        <AvatarComponent name={textValue.slice(0, 1).toUpperCase()} size="sm" />
                                        <div className='flex flex-col '>
                                            <p className=''>{textValue}</p>
                                        </div>
                                    </div>
                                    <p className='text-xs text-slate-400'>This email does not exists in the space.</p>
                                    <Button variant='flat' color='success' onClick={InviteToSpaceHandler}>Invite to space</Button>
                                </div> : "No members added."}</>}</> : "No members added."}</>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationUsers;