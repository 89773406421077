import React, { useState, useEffect } from 'react';
import { Chip, DropdownItem, Button, DropdownMenu, useDisclosure, Input, ModalHeader, ModalBody, Textarea, Tooltip, SelectItem, TableRow, TableCell, Select } from "@nextui-org/react";
import DropdownComponent from '../../Components/NextUi/Dropdown';
import FormJson from "./FormJson.json";
import ModalPopup from '../../Components/NextUi/ModalPopup';
import Toggle from '../../Components/NextUi/Toggle';
import ButtonComponent from '../../Components/NextUi/Button';
import TableComponent from "../../Components/NextUi/Table";
import { titleCreater } from '../../Controllers/basicControllers';
import { iconRender } from '../../Components/Navbar/items';
import { CreateData, UpdateData, userLoginDetails } from '../../Controllers/strapiController';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setData } from '../../Redux/actions';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const SchemaBuild = ({ darkMode, jsonSchema, setJsonSchema, setValidation, setErrorMessage, errorMessage, validattion, params, setReload }) => {
    // STATE INITIALIZATION
    const [selectedKeys, setSelectedKeys] = useState();
    const [selectedType, setSelectedType] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [minValue, setMinValue] = useState("1");
    const [maxValue, setMaxValue] = useState("100");
    const [stepValue, setStepValue] = useState("1");
    const [charLimit, setCharLimit] = useState("100");
    const [selectedFalseFields, setSelectedFalseFields] = useState({ properties: { toggleFalse: {}, toggleTrue: {} } });
    const [dropdownOptions, setDropdownOptions] = useState("");
    const [radioOptions, setRadioOptions] = useState("");
    const [isRequired, setIsRequired] = useState(false);
    const [isMultiSelect, setIsMultiSelect] = useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [fileAccepted, setFileAccepted] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editingFieldId, setEditingFieldId] = useState(null);
    const [errors, setErrors] = useState({});
    const [validate, setValidate] = useState(false);
    const userID = userLoginDetails()?.userId;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // DROPDOWN FIELDS TO CREATE FORM
    const fields = FormJson;
    // GENERATE A TIMESTAMP-BASED ID 
    const generateFieldId = () => Date.now().toString();

    // TABLE HEADER
    const tableHeader = ['Name', "Type", " "];
    // FILES ACCEPT DROPDOWN
    const filesAccept = ['Pdf', 'Svg', 'Xlsx', 'Docx', 'Jpeg'];

    // DROPDOWN TRIGGER ITEM
    const dropdownData = () => {
        return <Button color={'secondary'} variant='solid' size='sm'>Add a new field</Button>
    }

    // DORPDOWN SELECT ITEM HANDLER
    const handleSelect = (data) => {
        setSelectedKeys(data.label);
        setSelectedType(data.name);
        setIsEditing(false);
        setTitle('');
        onOpen();
    }

    // EXTRACTS ONLY RELATION FIELDS
    const findRelatedFields = (schema) => {
        const relatedFields = {};

        schema && Object.entries(schema)?.forEach(([key, value]) => {
            if (value?.relation) {
                relatedFields[key] = value;
            }
        });
        return relatedFields;
    }
    const relatedData = findRelatedFields(jsonSchema?.properties);

    useEffect(() => {
        if (params) {
            Object.keys(relatedData)?.forEach(data =>
                setSelectedFalseFields({ properties: relatedData[data]?.properties })
            )
        }
    }, [params])


    // CREATE FORM VALIDATIONS 
    const validateFields = () => {
        const newErrors = {};
        if (!title) newErrors.title = "Title is required.";
        if (selectedKeys === 'integer' || selectedKeys === 'slider') {
            if (minValue === null || minValue === "") newErrors.minValue = "Minimum value is required.";
            if (maxValue === null || maxValue === "") newErrors.maxValue = "Maximum value is required.";
        }
        if (selectedKeys === 'slider' && (stepValue === null || stepValue === "")) {
            newErrors.stepValue = "Step value is required.";
        }
        if (selectedKeys === 'textarea' && (charLimit === null || charLimit === "")) {
            newErrors.charLimit = "Character limit is required.";
        }
        if (selectedKeys === 'dropdown' && !dropdownOptions) {
            newErrors.dropdownOptions = "Dropdown options are required.";
        }
        if (selectedKeys === 'radio' && !radioOptions) {
            newErrors.dropdownOptions = "Dropdown options are required.";
        }
        if (selectedKeys === 'file' && !fileAccepted) {
            newErrors.fileAccepted = "File type is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const generateJson = (onClose) => {
        if (!validateFields()) return setValidate(true);
        setValidate(false);
        const fieldId = isEditing ? editingFieldId : generateFieldId();
        let json = {
            id: fieldId,
            title,
            description,
            type: selectedType,
            fieldType: selectedKeys,
            required: isRequired,
        };

        if (selectedKeys === 'integer' || selectedKeys === 'slider') {
            json.minValue = minValue;
            json.maxValue = maxValue;
        }

        if (selectedKeys === 'slider') {
            json.stepValue = stepValue;
        }

        if (selectedKeys === 'textarea') {
            json.charLimit = charLimit;
        }

        if (selectedKeys === 'dropdown') {
            json.options = dropdownOptions.split(',').map(opt => opt.trim());
            json.multiSelect = isMultiSelect;
        }

        if (selectedKeys === 'radio') {
            json.options = radioOptions.split(',').map(opt => opt.trim());
            json.multiSelect = isMultiSelect;
        }

        if (selectedKeys === 'file') {
            json.fileAccepted = fileAccepted;
        }
        if (selectedKeys === 'boolean') {
            const hasToggleFalse = selectedFalseFields?.properties?.toggleFalse &&
                Object.keys(selectedFalseFields.properties.toggleFalse).length > 0;
            const hasToggleTrue = selectedFalseFields?.properties?.toggleTrue &&
                Object.keys(selectedFalseFields.properties.toggleTrue).length > 0;

            if (hasToggleFalse || hasToggleTrue) {
                json.relation = 'relation';
                json.defaultValue = false;
                json.properties = {
                    toggleFalse: selectedFalseFields?.properties?.toggleFalse || {},
                    toggleTrue: selectedFalseFields?.properties?.toggleTrue || {}
                };
            }
        }

        const updatePropertiesById = (propertiesObj, fieldId, newJson) => {
            Object.keys(propertiesObj).forEach(key => {
                if (propertiesObj[key].id === fieldId) {
                    propertiesObj[key] = { ...propertiesObj[key], ...newJson };
                }
                // If nested properties exist (like toggleTrue and toggleFalse), update
                if (propertiesObj[key].properties) {
                    const { toggleTrue, toggleFalse } = propertiesObj[key].properties;
                    if (toggleTrue) updatePropertiesById(toggleTrue, fieldId, newJson);
                    if (toggleFalse) updatePropertiesById(toggleFalse, fieldId, newJson);
                }
            });
        };

        // Update the main properties object and any nested fields if they exist
        setJsonSchema(prevState => {
            const updatedProperties = { ...prevState.properties };

            // Update in the main properties object
            updatePropertiesById(updatedProperties, fieldId, json);

            // Add the new field if it's not found in the existing structure
            if (!Object.values(updatedProperties).some(item => item.id === fieldId)) {
                updatedProperties[title] = json;
            }

            return {
                ...prevState,
                properties: updatedProperties
            };
        });

        resetForm();
        onClose();
    }

    const resetForm = () => {
        setTitle("");
        setDescription("");
        setIsEditing(false);
        setMinValue("1");
        setMaxValue("100");
        setStepValue("1");
        setCharLimit("1");
        setDropdownOptions("");
        setIsRequired(false);
        setIsMultiSelect(false);
        setFileAccepted("");
        setEditingFieldId(null);
    };

    const editField = (fieldId) => {
        const field = jsonSchema.properties[fieldId];

        setTitle(field.title);
        setDescription(field?.description);
        setSelectedType(field.type);
        setSelectedKeys(field.type.toLowerCase());
        setMinValue(field.minValue || null);
        setMaxValue(field.maxValue || null);
        setStepValue(field.stepValue || null);
        setCharLimit(field.charLimit || null);
        setDropdownOptions(field.options?.join(", ") || "");
        setRadioOptions(field.options?.join(", ") || "");
        setIsRequired(field.required);
        setIsMultiSelect(field.multiSelect || false);
        setFileAccepted(field.fileAccepted || "");
        setIsEditing(true);
        setEditingFieldId(field?.id);
        onOpen();
        setSelectedKeys(field.fieldType);
        if (field?.relaion) {
            setSelectedFalseFields({ properties: field?.properties })
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!jsonSchema.Name) { newErrors.Name = 'Name is required.' }
        if (!jsonSchema.Description) { newErrors.Description = 'Description is required.' }
        if (!jsonSchema.Application) { newErrors.Application = 'Application is required.' }
        if (Object.keys(jsonSchema.properties).length === 0) { newErrors.properties = 'Schema is required to build your survey.' }
        setErrorMessage(newErrors);
        if (Object.keys(newErrors).length > 0) {
            return false;
        }
        return true;
    }

    const handleSelectionChange = (selectedFields, isTrue) => {
        const toggleKey = isTrue ? 'toggleTrue' : 'toggleFalse';

        const selectedFieldArray = selectedFields?.split(',') || [];

        setSelectedFalseFields(prevState => {
            const updatedFields = { ...prevState.properties[toggleKey] };

            // Add or remove selected fields
            Object.keys(jsonSchema.properties).forEach(field => {
                if (selectedFieldArray.includes(field)) {
                    // Add field if selected
                    updatedFields[field] = jsonSchema.properties[field];
                } else {
                    // Remove field if unselected
                    delete updatedFields[field];
                }
            });

            return {
                ...prevState,
                properties: {
                    ...prevState.properties,
                    [toggleKey]: updatedFields
                }
            };
        });
    };

    const submitHandler = async () => {
        if (!validateForm()) {
            setValidation(true);
            return;
        }

        const payload = {
            Name: jsonSchema.Name,
            Description: jsonSchema.Description,
            JSONSchema: {
                name: jsonSchema.Name,
                description: jsonSchema.Description,
                properties: { ...jsonSchema.properties }
            },
            application: {
                disconnect: [],
                connect: [{ id: parseInt(jsonSchema?.Application), position: { end: true } }]
            },
            author: {
                disconnect: [],
                connect: [{ id: userID, position: { end: true } }]
            },
        }

        const response = params ? await UpdateData("survey-schemas", params, payload) : await CreateData("survey-schemas", payload);

        if (response) {
            if (response.data) {
                CustomToast("Survey created successfully");
                navigate('/survey');
                dispatch(setData(response.data));
            } else {
                CustomToast("Something went wrong try after some time.");
                navigate('/survey');
                setReload(response);
                dispatch(setData(response.data));
            }
        }
    }

    const removeFieldHandler = (key, id) => {
        setJsonSchema(prevState => {
            const updatedProperties = { ...prevState.properties };
            delete updatedProperties[key];
            Object.keys(relatedData)?.forEach(data => {
                delete updatedProperties[data]?.properties?.toggleFalse?.[key];
                delete updatedProperties[data]?.properties?.toggleTrue?.[key];
            })

            return {
                ...prevState,
                properties: updatedProperties
            };
        });
        setSelectedFalseFields(prevState => {
            const updatedProperties = { ...prevState.properties };
            delete updatedProperties?.toggleTrue[key];
            delete updatedProperties?.toggleFalse[key];
        })
    };


    const DropdownItems = () => {
        return (
            <DropdownMenu
                aria-label="Single selection example"
                variant="flat"
                disallowEmptySelection>
                {fields?.map((data, index) => {
                    return <DropdownItem key={`${data.label}`} onClick={() => handleSelect(data)}>{data.name}</DropdownItem>
                })}
            </DropdownMenu>
        );
    }

    const SelectorFields = () => {
        const textareaProps = {
            variant: 'faded',
            label: "Enter Values Option by Space-Separate",
            labelPlacement: "outside",
            placeholder: "Value1, Value2, Value3",
            // isRequired:,
            maxRows: 10,
            minRows: 3,
            className: "col-span-12 md:col-span-6 mb-6 md:mb-0"
        }
        switch (selectedKeys) {
            case 'integer':
            case 'slider':
                return <>
                    <Input isRequired={false} type='number' variant='faded' label='Min Value' size='md' labelPlacement='outside' placeholder=" " value={minValue} defaultValue={1} onChange={(e) => setMinValue(e.target.value)} errorMessage={validate && minValue === '' ? errors.minValue : ''} isInvalid={validate && minValue === ''} />
                    <Input isRequired={false} type='number' variant='faded' label='Max Value' size='md' labelPlacement='outside' placeholder=" " value={maxValue} defaultValue={100} onChange={(e) => setMaxValue(e.target.value)} errorMessage={errors.maxValue} isInvalid={validate && errors.maxValue} />
                    {selectedKeys === 'slider' && (
                        <Input isRequired={false} type='number' variant='faded' label='Step Value' size='md' labelPlacement='outside' placeholder=" " value={stepValue} defaultValue={1} onChange={(e) => setStepValue(e.target.value)} errorMessage={validate && stepValue === '' ? errors.stepValue : ''} isInvalid={validate && stepValue === ''} />
                    )}
                </>
            case 'textarea':
                return <Input isRequired={false} type='number' variant='faded' label='Char Limit' size='md' labelPlacement='outside' placeholder=" " value={charLimit} defaultValue={charLimit} onChange={(e) => setCharLimit(e.target.value)} errorMessage={validate && charLimit === '' ? errors.charLimit : ''} isInvalid={validate && charLimit === ''} />
            case 'dropdown':
                return <Textarea
                    {...textareaProps}
                    value={dropdownOptions}
                    onChange={(e) => setDropdownOptions(e.target.value)}
                    errorMessage={validate && dropdownOptions === '' ? errors.dropdownOptions : ''}
                    isInvalid={validate && dropdownOptions === ''}
                />
            case 'radio':
                return <Textarea
                    {...textareaProps}
                    value={radioOptions}
                    onChange={(e) => setRadioOptions(e.target.value)}
                    errorMessage={validate && radioOptions === '' ? errors.radioOptions : ''}
                    isInvalid={validate && radioOptions === ''}
                />
            case 'file':
                return <Select
                    label="Files Accepted"
                    placeholder=" "
                    isRequired
                    labelPlacement='outside'
                    defaultSelectedKeys={[fileAccepted]}
                    onChange={(e) => setFileAccepted(e.target.value)}
                    className="max-w-full"
                    scrollShadowProps={{ isEnabled: false }}
                    errorMessage={validate && fileAccepted === '' ? errors.fileAccepted : ''}
                    isInvalid={validate && fileAccepted === ''}
                >
                    {filesAccept?.map((data, index) => (
                        <SelectItem key={`${data}`} value={data?.toLowerCase()}>{data}</SelectItem>
                    ))}
                </Select>

            default:
                return null;
        }
    }

    const tableBodyComponent = () => {
        const data = jsonSchema?.properties;

        return data && Object.keys(data).map((key) => (
            <TableRow key={`${data[key].id}`}>
                <TableCell>{titleCreater(data[key]?.title)}{data[key]?.required ? <span className='text-danger'>*</span> : ''}</TableCell>
                <TableCell>{data[key]?.type}</TableCell>
                <TableCell className='flex justify-end'>
                    <div className='flex flex-row gap-5'>
                        <div onClick={() => editField(key)}>
                            <Tooltip content="Edit">
                                {iconRender("https://cdn.lordicon.com/lzgmgrnn.json", "Edit")}
                            </Tooltip>
                        </div>
                        <div onClick={() => removeFieldHandler(key, data[key].id)}>
                            <Tooltip content='Delete'>
                                {iconRender("https://cdn.lordicon.com/wpyrrmcq.json", "Delete")}
                            </Tooltip>
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        ));
    };

    const commonProp = {
        type: 'text',
        variant: 'faded',
        size: 'md',
        labelPlacement: 'outside',
        placeholder: " ",
    }


    const ModalBodyData = () => {
        return <>
            <ModalHeader className="flex flex-col  gap-1">{isEditing ? `Edit ${selectedKeys}` : `Add a ${selectedKeys}`} field</ModalHeader>
            <ModalBody className='gap-0 h-96'>
                <div className='flex flex-col gap-4'>
                    <Input {...commonProp} label='Title' value={title} isDisabled={isEditing && true} onChange={(e) => setTitle(e.target.value)} isRequired isInvalid={validate && title === '' ? true : false} errorMessage={validate && title === '' ? errors.title : ''} />
                    <Input  {...commonProp} label='Description' description="additional info" value={description} onChange={(e) => setDescription(e.target.value)} />
                </div>
                <div className='flex flex-col w-full gap-4 pt-4'>
                    {SelectorFields()}
                </div>
                {selectedKeys === 'boolean' && <div className='flex flex-row gap-8 items-start'>
                    <Select
                        label="Select questions"
                        {...commonProp}
                        size='sm'
                        placeholder=" "
                        description="questions selected here will only be visible when the response is false."
                        className="max-w-xs"
                        defaultSelectedKeys={Object.keys(selectedFalseFields?.properties?.toggleFalse)}
                        selectionMode="multiple"
                        onChange={(e) => handleSelectionChange(e?.target?.value, false)}
                    >
                        {Object.keys(jsonSchema?.properties)?.map(data => {
                            if (data !== title) {
                                return <SelectItem key={data} value={data}>
                                    {data}
                                </SelectItem>
                            }
                        })}
                    </Select>
                    <Select
                        label="Select questions"
                        {...commonProp}
                        selectionMode="multiple"
                        size='sm'
                        placeholder=" "
                        description="questions selected here will only be visible when the response is true."
                        className="max-w-xs"
                        defaultSelectedKeys={Object.keys(selectedFalseFields?.properties?.toggleTrue)}
                        onChange={(e) => handleSelectionChange(e?.target?.value, true)}
                    >
                        {Object.keys(jsonSchema?.properties)?.map(data => {
                            if (data !== title) {
                                return <SelectItem key={data} value={data}>
                                    {data}
                                </SelectItem>
                            }
                        })}
                    </Select>
                </div>}

                <div className='flex flex-row gap-12'>
                    <Toggle title={'Make Required'} isSelected={isRequired} onValueChange={setIsRequired} />
                    {selectedKeys === 'dropdown' && <Toggle title={'Multi-Select'} isSelected={isMultiSelect} onValueChange={setIsMultiSelect} />}
                </div>
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <ButtonComponent size='sm' color='none' func={() => { resetForm(); onClose(); }} title='Cancel' />
            <ButtonComponent size='sm' color='danger' variant={'faded'} func={() => generateJson(onClose)} title={isEditing ? 'Update' : 'Add'} />
        </>
    }

    return (
        <div className='min-h-[200px] max-h-[700px]'>
            <div className='flex flex-row items-center gap-2 pb-4'>
                <h1 className='text-base font-medium'>Build Your Survey Schema</h1>
                <Chip color="danger" variant="flat" size='sm' radius='sm'>Beta</Chip>
            </div>
            <p>Choose a data type and begin building your survey forms</p>
            <div className='flex flex-row justify-end pt-2'>
                <DropdownComponent darkMode={darkMode} DropdownTriggerItem={dropdownData} DropdownItems={DropdownItems} />
            </div>
            <div className='pt-8'>
                {jsonSchema && <TableComponent headerComponent={tableHeader} bodyComponent={tableBodyComponent} />}
                {validattion && (Object.keys(jsonSchema.properties).length === 0) ? <p className='text-xs flex justify-center pt-4 text-danger'>{errorMessage.properties}</p> : ''}
            </div>
            <div className='pt-8 flex justify-end'>
                <ButtonComponent color='secondary' variant={'faded'} func={() => submitHandler()} title={params ? 'Update' : 'Submit'} size='sm' />
            </div>
            <ModalPopup
                size="2xl"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside"
            />
        </div>
    )
}

export default SchemaBuild;
