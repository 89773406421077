import React,{useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import AvatarComponent from "../../Components/NextUi/Avatar";
import CustomToast from "../../Components/BasicComponents/CustomToast";
import {avatarName, colorPicker, decriptedText} from "../../Controllers/basicControllers";
import { userLoginDetails,CommonCollectionFilterApi } from '../../Controllers/strapiController';
import TabsComponent from "../../Components/NextUi/Tabs";
import {Tab} from "@nextui-org/react";
import EditRollout from "../Rollout/EditRollout";
import Settings from '../../Components/BasicComponents/Settings';
import LogsTable from './logsTable';

const SingleKeys = () => {

    const [keys,setKeys] = useState();
    const [loader,setLoader] = useState();
    const [reload, setReload] = useState();
    const params=useParams();
    const userID=userLoginDetails()?.userId;
    const singleKey=decriptedText(params?._keyId)

    useEffect(() => {
        setLoader(true);
        const url=`filters[id][$eq]=${singleKey}&filters[$or][0][users_permissions_user][id][$in]=${userID}&[populate][users_permissions_users][on]`
        CommonCollectionFilterApi("keys", url)
     .then((data)=>{setKeys(data.data?.[0]);setLoader(false)})
     .catch((error)=>{
        setLoader(false);
        CustomToast("Something went wrong. Please try after sometime.");
     })
    }, [params,reload])

    const tabs = [
        {
            id: "logs",
            label: "Logs",
            content:   <LogsTable logs={keys?.attributes?.Logs} />
        },
        {
            id: "settings",
            label: "Settings",
            content: <Settings  data={keys} setReload={setReload} collectionName={"keys"}/>
        }]

    const tabsComponent = () => {
        return tabs?.map((item) => (
            <Tab key={item.id} title={item.label}>
                {item.content}
            </Tab>
        ))
    }

  return (
    <div>
          <div className='flex flex-row gap-4 items-center'>
                <AvatarComponent src='' name={avatarName(keys?.attributes?.Name)} color={colorPicker(keys?.id)} radius='sm' />
                <p className='text-xl font-normal'>{keys?.attributes?.Name}</p>
            </div>
            <div className='pt-8 pb-8'>
               {keys?.attributes?.Description}
            </div>
            <TabsComponent tabsComponent={tabsComponent} tabs={tabs} variant={"underlined"} color={colorPicker(keys?.id)} />
    </div>
  )
}

export default SingleKeys;