import React from 'react';
import {Card, CardBody,CardHeader} from "@nextui-org/react";

const CardComponent = ({shadow,radius, className, cardHeader, cardBody}) => {
  return (
   <Card shadow={shadow} radius={radius} className={className}>
    {cardHeader&&<CardHeader>{cardHeader()}</CardHeader>}
    <CardBody>
    {cardBody()}
    </CardBody>
   </Card>
  )
}

export default CardComponent;