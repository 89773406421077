import React from 'react';

const Titlecomponent = ({title,description}) => {
    return (
        <div className='mb-4'>
            <div className='text-3xl font-medium'>
                {title}
            </div>
            <div className='mt-4 leading-relaxed text-sm'>
                {description}
            </div>
        </div>
    )
}

export default Titlecomponent;