import React, { useState } from 'react';
import ModalPopup from "../../Components/NextUi/ModalPopup";
import ButtonComponent from "../../Components/NextUi/Button";
import { ModalHeader, ModalBody, Progress } from '@nextui-org/react';
import ChannelsList from './ChannelsList';
import ChannelsForm from './ChannelsForm';
import { CreateData } from "../../Controllers/strapiController";
import { toast, Toaster } from "react-hot-toast";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const CreateChannel = ({ isOpen, onOpenChange, darkMode, appId, setFormData, formData, step, setStep, setReload }) => {
    const [formError, setFormError] = useState();
    const [validateForm, setValidateForm] = useState(false);

    // WIZARD NEXT BUTTON FUNCTION
    const handleNext = () => {
        setStep(prevStep => prevStep + 1);
    };

    // WIZARD PREVIOUS BUTTON FUNCTION
    const handlePrevious = () => {
        setStep(prevStep => prevStep - 1);
    };

    // SWITCH CASE FOR WIZARD
    const renderPage = () => {
        switch (step) {
            case 1:
                return <ChannelsList darkMode={darkMode} onNext={handleNext} formData={formData} setFormData={setFormData} />;
            case 2:
                return <ChannelsForm darkMode={darkMode} onNext={handleNext} onPrevious={handlePrevious} setFormData={setFormData} formData={formData} formError={formError} validateForm={validateForm} />;
            default:
                return null;
        }
    };

    // VALIDATION FUNCTION
    const validation = () => {
        const schema = formData?.channel?.attributes?.Schema?.properties;
        let isValid = true;
        const errors = {};

        if (schema) {
            Object.keys(schema).forEach((field) => {
                const value = formData.properties?.[schema[field]?.title];
                const isRequired = schema[field]?.required;
                const type = schema[field]?.type;

                // Check for required fields
                if (isRequired && !value) {
                    isValid = false;
                    errors[schema[field]?.title] = `${schema[field]?.title} is required`;
                }

                // Check for specific types (e.g., number)
                if (type === 'number' && value && isNaN(Number(value))) {
                    isValid = false;
                    errors[schema[field]?.title] = `${schema[field]?.title} must be a number`;
                }
            });
        }

        setFormError(errors);
        return isValid;
    };


    const submitHandler = async (onClose) => {
        const validate = validation();
       
        const payload = {
            app_id: `${appId}`,
            json_response: { properties: formData?.properties },
            active: true,
            channel: {
                disconnect: [],
                connect: [{ id: formData?.channel?.id, position: { end: true } }]
            }
        }

        if (validate) {
            setValidateForm(false);
            const response = await CreateData('channel-responses', payload);
            if (response) {
                setStep(3);
                CustomToast("response submitted successfully.")
                setTimeout(() => {
                    onClose();
                    setReload(response);
                }, 300);
            }
        } else {
            setValidateForm(true);
        }
    }

    const ModalBodyData = () => {
        return <>
            <ModalHeader className="flex flex-col  gap-1">Create an channel </ModalHeader>
            <ModalBody className='gap-0 h-96'>
                <div className='flex justify-center'>
                    <Progress
                        size="sm"
                        radius="sm"
                        classNames={{ base: "max-w-lg" }}
                        color='secondary'
                        value={step === 1 ? 0 : 50 * (step - 1)}
                    />
                </div>
                {renderPage()}
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            {step !== 1 && <ButtonComponent title="previous" size='sm' color='none' variant="light" func={() => { handlePrevious(); }} />}
            <ButtonComponent title={step !== 1 ? "Submit" : "Next"} isDisabled={formData?.channel ? false : true} size='sm' color='secondary' variant='faded' func={() => { step !== 1 ? submitHandler(onClose) : handleNext() }} />
        </>
    }

    return (
        <>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <ModalPopup
                size="5xl"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside" />
        </>
    )
}

export default CreateChannel