import React from 'react';
import { Table, TableHeader, TableColumn, TableBody } from "@nextui-org/react";

const TableComponent = ({ headerComponent, bodyComponent, emptyContent, selectionMode, alignment }) => {
    return (
        <div>
            <Table removeWrapper aria-label="Example static collection table" selectionMode={selectionMode}>
                <TableHeader>
                    {headerComponent?.map((data, index) => (
                        <TableColumn key={`${index}`} align={alignment === data ? "end" : "start"}>{data}</TableColumn>
                    ))}
                </TableHeader>
                <TableBody emptyContent={emptyContent}>
                    {bodyComponent()}
                </TableBody>
            </Table>
        </div>
    )
}

export default TableComponent