import React from 'react';
import { Card, CardBody, Button } from "@nextui-org/react";

const DangerZone = ({onDeleteOpen, title}) => {
    return (
        <div>
            <div className='pt-4'>
                <Card className="shadow-none border border-danger rounded-2xl p-4">
                    <CardBody>
                        <p className='text-lg font-normal'>Danger zone</p>
                        <p className='text-lg font-normal pt-8  '>Delete your {title}</p>
                        <p className='text-sm pt-4'>This will permanently remove your collection you will lose access to all your data inside your {title}.</p>
                        <div className='pt-4 flex flex-row justify-end'>
                            <Button size='sm' onClick={onDeleteOpen} color='danger'>Delete {title}</Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}

export default DangerZone