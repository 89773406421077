import React from 'react';
import { Dropdown, DropdownTrigger, DropdownMenu } from "@nextui-org/react"

const DropdownComponent = ({ darkMode, DropdownTriggerItem, title, DropdownItems }) => {
    return (
        <Dropdown placement='center' className={ 'text-black bg-white'}>
            <DropdownTrigger>
                {DropdownTriggerItem()}
            </DropdownTrigger>
                {DropdownItems()}
        </Dropdown>
    )
}

export default DropdownComponent;