import React from 'react';
import Titlecomponent from "../../Components/BasicComponents/Titlecomponent";
import ButtonComponent from "../../Components/NextUi/Button";

const Channels = () => {
  const description="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ea ullam veniam voluptatem eaque cum provident voluptas dicta eum nulla rem!"
  return (
    <div>
      <Titlecomponent title="Channels" description={description}/> 
      <div className='flex flex-row justify-end'>
        <ButtonComponent title="+ Create a new channel" size='sm' color='danger' variant="default" />
      </div>
    </div>
  )
}

export default Channels;