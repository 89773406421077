import React, { useState, useEffect } from 'react';
import { Input, Textarea, useDisclosure } from "@nextui-org/react";
import AvatarComponent from "../../Components/NextUi/Avatar";
import ButtonComponent from "../../Components/NextUi/Button";
import { colorPicker, avatarName } from "../../Controllers/basicControllers";
import { UpdateData, DeleteSingleAttribute } from "../../Controllers/strapiController";
import { validateForm } from '../../utils/rolloutValidation';
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import DangerZone from '../../Components/BasicComponents/DangerZone';
import DeleteModal from "../../Components/BasicComponents/DeleteModal";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const initialErrorState = { name: '', description: '' };

const EditRollout = ({ darkMode, rollout, setReload }) => {
    const [createForm, setCreateForm] = useState();
    const [formError, setFormError] = useState(initialErrorState);
    const [validate, setValidate] = useState(false);
    const { isOpen: isDelete, onOpen: onDelete, onOpenChange: onOpenDelete } = useDisclosure();
    const navigate = useNavigate();

    useEffect(() => {
        if (rollout) {
            setCreateForm({
                name: rollout?.attributes?.Name,
                description: rollout?.attributes?.Description,
            })
        }
    }, [rollout])

    const commonProps = {
        isRequired: true,
        labelPlacement: 'outside',
        type: 'text',
        variant: 'faded',
        size: 'md',
        placeholder: ' '
    };

    // HANDLE ONCHANGE EVENT
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateForm((prev) => ({ ...prev, [name]: value }));
    }

    const submitHandler = async (onClose) => {

        const { isValid, errors } = validateForm(createForm);
        setFormError(errors);
        const payload = {
            Name: createForm?.name,
            Description: createForm?.description,
        }

        if (isValid) {
            setValidate(false);
            const response = await UpdateData('rollouts', rollout?.id, payload);
            if (response?.data) {
                CustomToast("Rollout updated successfully.");
                setReload(response);
            } else if (response.error) {
                CustomToast("Something went wrong. Not able to create app now. please try after some time.");
            }
        } else {
            setValidate(true);
        }
    }
    const DeleteHandler = async () => {
        const response = await DeleteSingleAttribute("rollouts", rollout?.id)
        if (response) {
            navigate("/rollout")
        }
    }

    return (
        <div className='flex flex-col gap-8 pt-8'>
            <Toaster
                position="bottom-right"
                reverseOrder={false}
            />
            <div className='flex flex-row gap-4 items-center'>
                {/* <AvatarComponent radius="full" size='md' src="" color={colorPicker(rollout?.id)} name={avatarName(createForm?.name)} className="" /> */}
                <Input className="w-full" {...commonProps} value={createForm?.name} label='Rollout Name' name="name" onChange={handleChange} errorMessage={validate && createForm.name === '' && formError.name} isInvalid={validate && createForm.name === '' && formError.name !== null} />
            </div>
            <Textarea  {...commonProps} label='Description' value={createForm?.description} name='description' onChange={handleChange} errorMessage={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description} isInvalid={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description !== null} />
            <div className='flex justify-end'>
                <ButtonComponent title="Update" size='sm' color={colorPicker(rollout?.id)} variant='faded' func={() => { submitHandler() }} />
            </div>
            <div>
                <DangerZone onDeleteOpen={onDelete} title="rollout" />
            </div>
            <DeleteModal
                isOpen={isDelete}
                onOpenChange={onOpenDelete}
                Collectionname={rollout?.attributes?.Name}
                setLoader={""}
                DeleteHandler={DeleteHandler} />
        </div>
    )
}

export default EditRollout;