export const iconRender = ( url, id) => {

    return <lord-icon
        src={url}
        trigger="hover"
        target={`#${id}`}
        state="morph-home-2"
        colors="primary:#black"
        style={{ width: "16px", height: "16px" }}>
    </lord-icon>
}

export const navItems = () => {
    const item = [
        {
            name: 'Overview',
            path: '/',
            id: 'Overview',
            url: "https://cdn.lordicon.com/nizfqlnk.json",
        },
        {
            name: 'Applications',
            path: '/applications',
            id: 'Applications',
            url: "https://cdn.lordicon.com/eouimtlu.json",
        },
        {
            name: 'Survey',
            path: '/survey',
            id: 'survey-builder',
            url: "https://cdn.lordicon.com/yxyampao.json",
        },
        {
            name: 'Channel',
            path: '/channel',
            id: 'Channel',
            url: "https://cdn.lordicon.com/baxknfaw.json",
        },
        {
            name: 'Rollout',
            path: '/rollout',
            id: 'rollout',
            url: "https://cdn.lordicon.com/vdjwmfqs.json",
        },
        {
            name: 'Insights',
            path: '/insights',
            id: 'insights',
            url: "https://cdn.lordicon.com/swcqkzdc.json",
        },
        {
            name: 'Keys',
            path: '/keys',
            id: 'Keys',
            url: "https://cdn.lordicon.com/trkmrggx.json",
        }
    ]

    const userItems = [
        {
            name: 'Settings',
            path: '/',
            url: "https://cdn.lordicon.com/fdxqrdfe.json",
        }
    ]

    return { item, userItems };
}

export const userDropDownItems = (darkMode, userNavigate, handleChange, logOutHandler) => {
    const items = [
        {
            title: "Profile",
            path: "Profile",
            id: "profile",
            Click: userNavigate,
            url: "https://cdn.lordicon.com/lecprnjb.json"
        },
        {
            title: "Feedback",
            id: "Onboarding",
            Click: handleChange,
            url: 'https://cdn.lordicon.com/zrtfxghu.json'
        },
        {
            title: "Logout",
            id: "Logout",
            Click: logOutHandler,
            url: "https://cdn.lordicon.com/vduvxizq.json"
        },
    ]

    return items
}