import CryptoJS from "crypto-js";
import { CommonCollectionFilterApi, UpdateData } from "./strapiController";
import { decriptedText } from "./basicControllers";

export const NavigateSign = async (email, password2, path) => {
  

    const response = await fetch(`${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/signin`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password2, path }),
    });

    const data = await response.json();
    
    const spaceName=path!==null?path?.split("/")?.[2]:"";
    
    const decriptid=decriptedText(`${spaceName}`)
    const url=`filters[id][$eq]=${decriptid}&[populate][users_permissions_users][on]`;
   
    const spacedata=await CommonCollectionFilterApi("applications",url);
  
    const userId=data?.userData?.user?.id;
    // Store the user data in session storage
    if (data.userData) {
        const relationData={
            users_permissions_users: { disconnect: [], connect: [{ id: parseInt(userId), position: { end: true } }] },
        }
        const spaceId=spacedata?.data?.[0]?.id;
        
        const response=spacedata?.data?.length>0 && await UpdateData("applications",parseInt(spaceId),relationData);
       
        sessionStorage.setItem("userData", JSON.stringify(data.userData));
    }

    return data.navigate_route;
};
