import './index.css';
import input from "./input.css";
import App from './App';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';
import store from './Redux/store';
import {Provider} from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode className='min-h-full text-foreground bg-background '>
    <NextUIProvider className={` text-foreground bg-background min-h-full max-h-screen max-w-screen-3xl relative container mx-auto flex-grow`}>
      <main className="mnpm install @nextui-org/react framer-motionin-h-full ">
      <Provider store={store}>
       <App/> 
       </Provider>
       </main>
    </NextUIProvider>
  </StrictMode>
);

reportWebVitals();
