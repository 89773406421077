export const validateForm = (form) => {
    const errors = {};
    let isValid = true;

    if (!form.name) {
        isValid = false;
        errors.name = 'Name is required.';
    }

    if (!form.description) {
        isValid = false;
        errors.description = 'Description id required.';
    } else if (form.description && form.description.length > 200) {
        isValid = false;
        errors.description = 'Description should not exceed 200 characters.';
    }

    if (form?.appId && !form?.appId) {
        isValid = false;
        errors.appId = 'Application is required.';
    }

    if (form?.surveyId && !form?.surveyId) {
        isValid = false;
        errors.surveyId = 'Survey is required.';
    }

    return { isValid, errors };
};