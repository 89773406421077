import React from "react";

const ShowMoreParamsInfo = ({ data = {} }) => {
  const length = Object.keys(data)?.length;
  if (!(length > 1)) return null;
  return (
    <div className="my-4 border-1 border-gray rounded-md p-2 text-sm">
      {Object.keys(data)?.map((key) => {
        const keyName = key.charAt(0).toUpperCase() + key.slice(1);
        return (
          <div className="flex items-center gap-4">
            <span className="font-medium">{keyName}:</span>
            <span className="text-gray">{data?.[key]}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ShowMoreParamsInfo;
