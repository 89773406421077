import React, { useState, useEffect } from 'react'
import { iconRender, navItems, userDropDownItems } from './items';
import { Image, DropdownItem, DropdownSection, Avatar, useDisclosure, DropdownMenu } from "@nextui-org/react";
import hayalogo from "../../Images/haya-logo.png";
import { Outlet, useNavigate, useLocation, NavLink } from "react-router-dom";
import Headers from "../Header/Header";
import DropdownComponent from '../NextUi/Dropdown';
import Continue from '../../Images/Continue';
import Feedback from '../Feedback';
import { userLoginDetails, CommonCollectionFilterApi } from '../../Controllers/strapiController';
import { StringLimit } from '../../Controllers/basicControllers';
import Loader from "../ErrorHandler/Loading";
import CustomToast from "../BasicComponents/CustomToast";
import { setApplication } from '../../Redux/actions';
import {useDispatch} from "react-redux";

const SideNavbar = ({ darkMode }) => {
    // STATE INITIALIZATION
    const [inputText, setInputText] = useState('');
    const [loader,setLoading] = useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const Location = useLocation();
    const URLPath = Location.pathname.split("/");
    const items = navItems();
    const navigate = useNavigate();
    const userData = userLoginDetails();
    const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));
    const location = useLocation();
    const dispatch=useDispatch();
    // SPACE API CALL
  useEffect(() => {
    setLoading(true);
    if (JWT_Testing === null) {
      if (location.pathname !== '') {
        navigate(`/sign-in/redirectTo?path=${location.pathname}`);
        setLoading(false);
      } else {
        navigate('/sign-in')
        setLoading(false);
      }
    }

        const url=`filters[$or][0][author][id][$in]=${JWT_Testing?.user?.id}&filters[$or][1][users_permissions_users][id][$in]=${JWT_Testing?.user?.id}&[populate][Logo][on]&[populate][users_permissions_users][on]`
        CommonCollectionFilterApi("applications",url)
          .then((data) => {dispatch(setApplication(data.data)); })
          .catch((error) => CustomToast("Something went wrong. Please try after sometime."))

    },[])

    // LOGOUT FUNCTION
    const logOutHandler = () => {
        sessionStorage.removeItem("userData");
        navigate("/sign-in");
    }

    // NAVIAGTE TO USER
    const userNavigate = () => {
        navigate("/settings")
    }

    // FEEDBACK ONCHANGE
    const handleChange = () => {
        onOpen();
        setInputText('');
    }

    // USER SETTINGS PAGE RENDERING
    const userItems = userDropDownItems(darkMode, userNavigate, handleChange, logOutHandler);

    const ProfileTrigger = () => {
        return <div className='flex items-center gap-2 pb-4 justify-evenly cursor-pointer'>
            <Avatar className='h-6 w-6' color={''} name={userData?.userDetails?.user?.email} />
            {userData && <span className='text-xs font-bold'>{StringLimit(userData?.userDetails?.user?.email, 20)}</span>}
            <Continue />
        </div>
    }

    const profileDropdown = () => {
        return <DropdownMenu
            aria-label="Single selection example"
            variant="flat"
            disallowEmptySelection
        >
            <DropdownSection aria-label="Profile & Actions">
                {userItems && userItems.map((items, index) => {
                    return <DropdownItem
                        key={`${index}`}
                        id={items.id}
                        onClick={items.Click}
                        onPress={() => items.Click}
                        startContent={iconRender( items.url, `#${items.name}`)}>
                        <span className='' >{items.title}</span>
                    </DropdownItem>
                })}
            </DropdownSection>
        </DropdownMenu>
    }

    // if(loader){
    //     return <Loader/>
    // }

    return (
        <div className='flex flex-row w-full'>
            <div className={`flex flex-col justify-between w-60 dark:bg-root h-screen flex-shrink-0 border-r border-divider bg-inherit px-4 dark:border-gray-600 md:flex`}>
                <div>
                    <div className='flex items-center h-14 gap-2 cursor-pointer'>
                        <div className='flex items-center h-14 pt-4 gap-2 cursor-pointer'>
                            <Image
                                width={30}
                                alt="NextUI hero Image"
                                src={hayalogo} />
                            <a href className='font-bold'>Survey Builder</a>
                        </div>
                    </div>
                    <nav className='mt-4 flex-1'>
                        <ul className='flex flex-col gap-2'>
                            {items?.item.map((data, index) => {
                                const Verified = `/${URLPath[1]}` === data.path;
                                return <li key={`${index}`}>
                                    <NavLink className=' rounded-md' to={data.path} id={data.id}>
                                        <span className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 text-black hover:bg-grey-200 hover:text-foreground 
                                            ${Verified ? 'text-foreground bg-grey-200' : ''}`}>
                                            {iconRender( data.url, data.id)}
                                            {data.name}
                                        </span>
                                    </NavLink>
                                </li>
                            })}

                        </ul>
                    </nav>
                </div>
                <div className='flex w-full'>
                    {userData?.userDetails?.user ? <DropdownComponent darkMode={darkMode} title={''} DropdownTriggerItem={ProfileTrigger} DropdownItems={profileDropdown} />
                        : <NavLink className='rounded-md w-full py-3' id="logOut" to={"/sign-in"} >
                            <span className={`flex p-1 items-center gap-2 rounded-md px-2 text-sm hover:bg-grey-200 $ 'text-black hover:bg-grey-200 hover:text-foreground`}>
                                {iconRender( "https://cdn.lordicon.com/vduvxizq.json", "logOut")}
                                Log in
                            </span>
                        </NavLink>
                    }
                </div>
            </div>
            <div className='flex flex-col w-full'>
                <Headers darkMode={darkMode} />
                <div className='h-[calc(100vh-60px)] overflow-auto '>
                    <div className='max-w-6xl w-full mx-auto py-8 px-4'>
                        {/* <BreadcrumbsTabs/> */}
                        <Outlet />
                    </div>
                </div>
            </div>
            <Feedback
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                setInputText={setInputText}
                inputText={inputText} />
        </div>
    )
}

export default SideNavbar;