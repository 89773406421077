import { Avatar, Button, Card, CardBody, CircularProgress, Input, ModalBody, ModalHeader, useDisclosure, NextUIProvider } from '@nextui-org/react'
import React, { useEffect, useRef, useState } from 'react'
import { DeleteSingleAttribute, UpdateOneUserData, getOneData, uploadFile,userLoginDetails } from '../../Controllers/strapiController';
import ModalPopup from '../../Components/NextUi/ModalPopup';
import DeleteModal from '../../Components/BasicComponents/DeleteModal';
import { useNavigate } from "react-router-dom";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import pluralize from "pluralize";
import CryptoJS from "crypto-js";
import { useDispatch } from "react-redux";
import { setUserData } from "../../Redux/actions";
import Loading from '../../Components/ErrorHandler/Loading';
import { avatarName, colorPicker, displayName } from '../../Controllers/basicControllers';

const UserSettings = ({ darkMode }) => {
  // STATE INITIALIZATION
  const [UserDetails, setUserDetails] = useState(null);
  const [Loader, setLoader] = useState(true);
  const UserId = userLoginDetails()?.userId;
  const fileInputRef = useRef();
  const { isOpen: isModalOpen, onOpen: onModalOpen, onOpenChange: onModalOpenChange } = useDisclosure();
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onOpenChange: onDeleteOpenChange } = useDisclosure();
  const [FileUpload, setFileUpload] = useState(null);
  const [fileUploaded, setFileUploaded] = useState();
  const [Response, setResponse] = useState('');
  const [notification, setNotification] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const Navigate = useNavigate();
  const [imageLoader, setImageLoader] = useState(false);
  var PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  const [passwordError, setPasswordError] = useState('');
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ Firstname: '', Lastname: '', Picture: '', Theme: '', Password: '' });

  const commonProps={
    key:"outside",
    type:"text",
    size:'sm',
    label:"",
    labelPlacement:"outside"
  }

  // API RENDERING
  useEffect(() => {
    setLoader(true);
    getOneData("users", UserId)
      .then((data) => {
        setTimeout(() => { setLoader(false); }, 300);
        setFormData({
          Firstname: data.firstname,
          Lastname: data.lastname,
          Picture: data && data.ProfilePicture !== null?`${process.env.REACT_APP_STRAPI_IP_ADDRESS}${data.ProfilePicture.url}` :"",
        })
        setUserDetails(data)
        dispatch(setUserData(data))
      })
  }, [Response, UserId])

      // FILE UPLOAD FUNCTION
      useEffect(() => {
        const UploadFileHandler = async () => {
            setImageLoader(true);
            const response = await uploadFile(FileUpload);
            if (response) {
                setImageLoader(false);
                const URLresponse = response && response[0];
                setFileUploaded(URLresponse);
            }
        };
        if (FileUpload) {
            UploadFileHandler();
        }
    }, [FileUpload]);

  const UserProfile = UserDetails && UserDetails.ProfilePicture !== null ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${UserDetails?.ProfilePicture?.url}` : "";
  const UploadedUser = fileUploaded && fileUploaded !== null? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${fileUploaded.url}` :"";

  // FUNCTION TO UPDATE USER DATA
  const UpdateProfile = async (onClose) => {
    setLoader(true);
    const payload = {
      firstname: formData.Firstname,
      lastname: formData.Lastname,
      ProfilePicture: fileUploaded,
    }

    const response = await UpdateOneUserData("users", UserId, payload);
    if (response) {
      onClose();
      setNotification(true);
      setTimeout(() => {
        setLoader(false);
        setResponse(response);
      }, 500);
    }
  }

  useEffect(() => {
    if (notification === true) {
      // Notify("User profile successfully updated.");
    }
  }, [notification])

  // ONCHANGE FUNCTION FOR FILE UPLOAD
     const handleFileChange = (event) => {
      const files = event.target.files;
      setImageLoader(true);
      if (files && files[0]) {
          const file = files[0];
          setFileUpload(file);
          setImageLoader(false);
      } else {
          setFileUpload(null);
          setImageLoader(false);
      }
  }

  // REMOVE USER PROFILE PICTURE FINCTION
  const Handleremove = async () => {
    setFileUploaded(null);
    setFormData({ ...formData, Picture: "" })
  }


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // TO DELETE USER ALL RELATIONS AND RELATED DATA NEEDS TO DELETE. LISTING ALL THE RELATION DATA
  const UserWithRelations = (userData) => {
    const RelationType = [];
    for (const key in userData) {
      if (key === "tag") {
        RelationType.push({ [key]: [userData[key]] })
      }
      if (Array.isArray(userData[key])) {
        if (key === "collections" || key === "connectors" || key === "spaces" || key === "tag") {
          RelationType.push({ [key]: userData[key] })
        }
      }
    }
    return RelationType;
  }

  const RelationData = UserDetails && UserWithRelations(UserDetails);

  // DELETE USER FUNCTION WITH RELATED DATA
  const DeleteHandler = async () => {
    setLoader(true);
    try {
      const promises = RelationData?.map(async (data) => {
        for (const key in data) {
          if (Array.isArray(data?.[key])) {
            return Promise.all(data?.[key]?.map(async (newdata) => {
              const PluralizeData = key&&pluralize.plural(key)
              await DeleteSingleAttribute(PluralizeData, newdata.id);
            }));
          }
        }
      });

      await Promise.all(promises);

      const response = await DeleteSingleAttribute("users", UserId);
      if (response) {
        setTimeout(() => {
          setLoader(false);
          Navigate("/sign-in");
          sessionStorage.clear();
        }, 500)
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setLoader(false);
    }
  };

  // PASSWORD EYE TOGGLE
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);

  // VALIDATION FORM
  const Validation = () => {
    var isValid = true;

    if (formData.Password !== "" && !PasswordRegex.test(formData.Password)) {
      isValid = false;
      setPasswordError("Enter a strong password.");
    } else {
      setPasswordError(null);
    }
    return isValid;
  }

  // PASSWORD CHANGE FUNCTION
  const HandlePasswordChange = async () => {
    const Validate = Validation()
    const secretKey = `${process.env.REACT_APP_ENCRYPT_SECRET_KEY}`;
    const inputString = formData.Password;
    const encrypted = CryptoJS.AES.encrypt(inputString, secretKey);
    const AESHash = encrypted.toString();
    const payload = {
      Password2: btoa(AESHash)
    }

    if (Validate) {
      setValidationError(true);
      await UpdateOneUserData("users", UserId, payload);
    } else {
      setValidationError(false);
    }
  }

  // MODAL 
  const ModalBodyData = () => {
    return <>
      <ModalHeader>
        Edit profile
      </ModalHeader>
      <ModalBody>
        <div className='flex flex-row items-center gap-6'>
          <Avatar radius="full" size='lg' src={UploadedUser ? UploadedUser : formData?.Picture} color={colorPicker(UserDetails.id)} name={avatarName(UserDetails?.firstname !== null ? UserDetails?.firstname :UserDetails?.email)} />
          <input ref={fileInputRef} type='file' onChange={(event) => handleFileChange(event)} className='hidden' />
          <Button onClick={handleButtonClick} size='sm' variant='bordered' >Select profile image</Button>
          {UserProfile ? <>{imageLoader ? <CircularProgress size="sm" aria-label="Loading..." /> : <p className='text-xs cursor-pointer' onClick={Handleremove}>Remove</p>}</> : ""}
        </div>
        <div className='flex flex-col gap-4 pt-4'>
          <div>
            <p className='text-sm font-normal text-slate-500 pb-1'>First name</p>
            <Input
             {...commonProps}
              value={formData && formData.Firstname}
              onChange={(e) => setFormData({ ...formData, Firstname: e.target.value })}
              placeholder="Enter your full name"
            />
          </div>
          <div>
            <p className='text-sm font-normal text-slate-500 pb-1'>Last name</p>
            <Input
               {...commonProps}
              defaultValue={formData && formData.Lastname}
              onChange={(e) => setFormData({ ...formData, Lastname: e.target.value })}
              placeholder="Enter your last name"
            />
          </div>
          <div className='cursor-not-allowed'>
            <p className='text-sm font-normal text-slate-500 pb-1'>Email</p>
            <Input
              isDisabled
              {...commonProps}
              value={UserDetails && UserDetails.email}
              placeholder="Enter your email"
            />
          </div>
        </div>
      </ModalBody>
    </>
  }

  // MODAL FOOTER
  const footerCreate = (onClose) => {
    return <>
      <Button variant='bordered' onClick={() => UpdateProfile(onClose)}>
        Update
      </Button>
    </>
  }

  // LOADER
  if (Loader) {
    return <Loading darkMode={darkMode}/>
  }

  // RETURN UI
  if (UserDetails) {
    return (
      <div>
          <div className='max-w-2xl m-auto flex flex-col gap-6 px-4'>
            <NextUIProvider>
              <Card className="shadow-none border border-divider rounded-2xl p-4">
                <CardBody>
                  <div className='flex flex-row items-center gap-8'>
                    <Avatar radius="full" size='md' classname="" color={colorPicker(UserDetails.id)} src={UserProfile} name={UserDetails?.firstname !== null ? UserDetails?.firstname?.slice(0, 1).toUpperCase() : UserDetails?.email?.slice(0, 1).toUpperCase()} />
                    <p className='text-lg font-medium'>{UserDetails?.firstname !== null ? UserDetails?.firstname : UserDetails?.email}</p>
                  </div>
                  <div className='flex flex-row w-full text-base pt-7 font-normal'>
                    <p className='w-1/3 text-slate-400'>Display Name</p>
                    <p className='w-full '>{UserDetails ? displayName(UserDetails.firstname, UserDetails.lastname, UserDetails.email) : "---"}</p>
                  </div>
                  <div className='flex flex-row w-full  text-base pt-3 font-normal'>
                    <p className='w-1/3 text-slate-400'>Email</p>
                    <p className='w-full'>{UserDetails?.email !== null ? UserDetails.email : ""}</p>
                  </div>
                  <div className='pt-6'>
                    <Button variant='bordered' className='text-base' size='sm' onPress={() => { onModalOpen(); }}>Edit profile</Button>
                  </div>
                </CardBody>
              </Card>
            </NextUIProvider>
            <NextUIProvider>
              <Card className="shadow-none border border-danger rounded-2xl p-4">
                <CardBody>
                  <p className='text-lg font-normal'>Danger zone</p>
                  <p className='text-lg font-normal pt-4'>Change password</p>
                  <p className='text-sm pt-4'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit, similique. Ullam labore ipsa magnam fuga numquam quidem blanditiis consectetur assumenda.</p>
                  <div className='pt-4 flex flex-row gap-4'>
                    {UserDetails && <Input
                      size="sm"
                      variant="flat"
                      placeholder="Enter your password"
                      isInvalid={formData.Password !== "" && (!PasswordRegex.test(formData.Password) || validationError) ? !PasswordRegex.test(formData.Password) || validationError : ""}
                      errorMessage={formData.Password !== "" && (!PasswordRegex.test(formData.Password) || validationError) ? passwordError : ""}
                      onChange={(e) => setFormData({ ...formData, Password: e.target.value })}
                      endContent={
                        <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                          {isVisible ? (
                            <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          ) : (
                            <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                          )}
                        </button>
                      }
                      type={isVisible ? "text" : "password"}
                      className="max-w-xs"
                    />}
                    <Button
                      isDisabled={formData.Password === ""}
                      onClick={HandlePasswordChange}
                      className="px-4"
                      size='sm'
                      color='secondary'>
                      Change password
                    </Button>
                  </div>
                  <p className='text-lg font-normal pt-8  '>Delete your account</p>
                  <p className='text-sm pt-4'>This will permanently remove your account you will lose access to all your data inside your space.</p>
                  <div className='pt-4'>
                    <Button size='sm' onPress={onDeleteOpen} className='text-base' color='danger'>Delete account</Button>
                  </div>
                </CardBody>
              </Card>
            </NextUIProvider>
          </div>
          <ModalPopup
            isOpen={isModalOpen}
            onOpenChange={onModalOpenChange}
            ModalBodyData={ModalBodyData}
            footer={footerCreate}
          />
          <DeleteModal
            isOpen={isDeleteOpen}
            onOpenChange={onDeleteOpenChange}
            deleteData={""}
            setDeleteData={""}
            Collectionname={"user"}
            setLoader={setLoader}
            DeleteHandler={DeleteHandler}
          />
      </div>
    )
  }
}

export default UserSettings