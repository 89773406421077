import React from 'react';
import { toast } from "react-hot-toast";

const CustomToast = (message) => {

    return (
        toast.custom((t) => (
            <div
                className={`${t.visible ? 'animate-enter' : 'animate-leave'
                    } max-w-xs w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
                <div className="p-2">
                    <p className="mt-1 text-sm text-gray-500">
                       {message}
                    </p>
                </div>
            </div>
        ))

    )
}

export default CustomToast