import React, { useState, useEffect } from 'react';
import TableComponent from "../../Components/NextUi/Table";
import { CommonCollectionFilterApi } from "../../Controllers/strapiController";
import ButtonComponent from "../../Components/NextUi/Button";
import { iconRender } from "../../Components/Navbar/items";
import { TableRow, TableCell, User, Link, useDisclosure, ModalHeader, ModalBody, Select, SelectItem, Checkbox } from "@nextui-org/react";
import { timeDifference, avatarName, colorPicker, downloadCSV, downloadJSONFile } from '../../Controllers/basicControllers';
import PaginationComponent from "../../Components/NextUi/Pagination";
import SurveyForm from '../SurveyForm/SurveyForm';
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import FunctionLoader from '../../Components/ErrorHandler/FunctionLoader';
import CustomToast from '../../Components/BasicComponents/CustomToast';
import ModalPopup from "../../Components/NextUi/ModalPopup";

const Response = ({ darkMode, rollout }) => {
    // STATE INITIALIZATION
    const headerComponent = ["Email", "Form", "CreatedAt", ""];
    const [responseSchema, setResponseSchema] = useState("");
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const [loader, setLoader] = useState(true);
    const [surveySchema, setSurveySchema] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [selectAttribute, setSelectAttribute] = useState([]);
    const [dropdownItem, setDropdownItem] = useState();
    const surveyId = rollout?.attributes?.survey_schema?.data?.id;

    // API CALL
    useEffect(() => {
        const url = `filters[metric_id][$eq]=${surveyId}&sort[0]=createdAt:desc`;
        CommonCollectionFilterApi("response-jsons", url)
            .then((data) => {
                if (data?.error) {
                    setLoader(false);
                    if (data?.error?.status === 404) { setNotFound(true) }
                    else if (data?.error?.status === 403) { setForbidden(true) }
                    else if (data?.error?.status === 500) { setServerIssue(true) }
                    else if (data?.error?.status === 401) { setAuthorized(true) }
                    else {
                        CustomToast("Something went wrong with collections, please try after some time.")
                    }
                }
                else if (data?.data) {
                    setResponseSchema(data?.data);
                    setLoader(false);
                }
            })
            .catch((error) => { setServerIssue(true); setLoader(false); })
    }, [])

    const schemaData = responseSchema?.[0]?.attributes?.ResponseJson?.properties;
    const arrayOfResponse = responseSchema?.length > 0 && responseSchema?.map(data => data?.attributes?.ResponseJson?.properties);


    const handleSelectAll = (checked) => {
        if (checked) {
            const array = schemaData && Object.keys(schemaData)?.map(data => data);
            setSelectAttribute(array);
        } else {
            setSelectAttribute([]);
        }
    }

    const handleChange = (checked, data) => {
        if (checked) {
            setSelectAttribute(pre => ([
                ...pre, data
            ]))
        } else {
            setSelectAttribute(prev => prev?.filter(field => field !== data))
        }
    }

    // Function to trigger CSV download
    const downloadCSVFile = () => {
        const responses = arrayOfResponse?.map((response) => {
            let filterData = {};
            selectAttribute?.forEach(data => {
                if (typeof response[data] === 'object') {
                    return filterData[data] = response[data]?.url;
                }
                filterData[data] = response[data]
            })
            return filterData;
        })

        if (dropdownItem === 'csv') {
            downloadCSV(responses);
        } else {
            downloadJSONFile(responses)
        }
    };

    const ModalBodyData = () => {
        return <><ModalHeader className="flex flex-col gap-1">Export the Response </ModalHeader>
            <ModalBody className='gap-0 h-96'>
                <Select
                    size='sm'
                    labelPlacement='outside'
                    label="Downloaded As"
                    placeholder="Select an export type"
                    className="w-full"
                    onChange={(e) => setDropdownItem(e.target.value)}
                >
                    <SelectItem key="csv" value='csv'>export as CSV</SelectItem>
                    <SelectItem key='json' value='json'>export as JSON</SelectItem>
                </Select>
                <div className='pt-8 flex flex-col gap-2'>
                    <div className='flex flex-row items-center'>
                        <Checkbox isIndeterminate={selectAttribute?.length !== 0 && selectAttribute?.length !== Object.keys(schemaData)?.length} size='sm' onChange={(e) => handleSelectAll(e.target.checked)}></Checkbox>
                        <p className='text-sm'>Select all the fields</p>
                    </div>
                    <div className='pl-7 flex flex-col gap-1'>
                        {Object.keys(schemaData).map((data, index) => {
                            return <div className='flex flex-col'>
                                <Checkbox size='sm'
                                    isSelected={selectAttribute?.includes(data) ? true : false}
                                    onChange={(e) => { handleChange(e.target.checked, data) }}>
                                    <span className='text-sm'>{data}</span>
                                </Checkbox>
                            </div>
                        })}
                    </div>
                </div>
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <ButtonComponent title="Cancel" size='sm' color='none' variant="light" func={() => { onClose() }} />
            <ButtonComponent isDisabled={dropdownItem ? false : true} title="Export" size='sm' color='secondary' variant='faded' func={() => { downloadCSVFile() }} />
        </>
    }

    const recordsPerPage = 10;

    // Calculate indexes for pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = responseSchema && responseSchema.slice(indexOfFirstRecord, indexOfLastRecord);

    const tableBodyComponet = () => {
        return currentRecords?.length > 0 && currentRecords?.map((data, index) => {
            return <TableRow key={`${index}`} onClick={() => { }} className='cursor-pointer'>
                <TableCell>
                    <User
                        avatarProps={{ radius: "sm", size: 'sm', src: "", color: colorPicker(data?.id), name: avatarName(data?.attributes?.email) }}
                        description={""}
                        name={data?.attributes?.email}>
                        {data?.attributes?.email}
                    </User>
                </TableCell>
                <TableCell>{data?.attributes?.ResponseJson?.surveyTitle}</TableCell>
                <TableCell>{timeDifference(data?.attributes?.createdAt)}</TableCell>
                <TableCell>
                    <Link showAnchorIcon onClick={() => setSurveySchema(data)} color="secondary" size='sm'>
                        View Response
                    </Link>
                </TableCell>
            </TableRow>
        })
    }

    // SUPPORTING RESPONSES
    if (notFound) { return <NotFound /> }
    if (serverIssue) { return <ServerIssue /> }
    if (forbidden) { return <Forbbiden /> }
    if (authorized) { return <UnAuthorized /> }
    if (loader) { return <FunctionLoader darkMode={darkMode} /> }

    return (
        <div className='pt-8'>
            {surveySchema ? <div>

                <div id='BackArrow' className='flex flex-row gap-1 items-center cursor-pointer' onClick={() => setSurveySchema(null)}>
                    <div className="transform rotate-90 flex w-fit" >
                        {iconRender("https://cdn.lordicon.com/rmkahxvq.json", "BackArrow")}
                    </div>
                    <p className='text-sm'>Back</p>
                </div>
                <SurveyForm surveySchema={surveySchema} darkMode={darkMode} />
            </div>
                : <>
                    {responseSchema?.length > 0 && <div className='flex justify-end pb-4'>
                        <ButtonComponent title="Export" color="secondary" variant="faded" size="sm" func={() => onOpen()} />
                    </div>}
                    <TableComponent
                        headerComponent={headerComponent}
                        bodyComponent={tableBodyComponet}
                        emptyContent={"No rollout created yet. Click on Create new rollout to create your first rollout"}
                        selectionMode="multiple"
                        alignment={""} /></>}

            {responseSchema?.length > 10 && <div className='flex justify-end pt-8'>
                <PaginationComponent
                    color={'secondary'}
                    totalPage={responseSchema}
                    currentRecords={currentRecords}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    recordsPerPage={recordsPerPage}
                />
            </div>}
            <ModalPopup
                size="xl"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside" />

        </div>
    )
}

export default Response;