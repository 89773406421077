import React,{useEffect} from 'react';
import {Input} from "@nextui-org/react";

const ChannelsForm = ({darkMode,onNext,onPrevious,setFormData,formData,formError,validateForm}) => {
  
  useEffect(() => {
    const schema=formData?.channel?.attributes?.Schema?.properties;
    
   if(formData?.channel){
    schema&&Object.keys(schema)?.map((data)=>(
    setFormData(prev=>({
      ...prev,
      properties: {
        ...prev.properties, 
        [schema[data]?.title]: "", 
      },
    }))
    ))
  }
  }, [formData?.channel])
  
// HANDLE ONCHANGE EVENT
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prev) => ({
    ...prev,
    properties: {
      ...prev.properties, 
      [name]: value, 
    },
  }));
};


  const formRender=()=>{
    const schema=formData?.channel?.attributes?.Schema?.properties;
   
   return schema&&Object.keys(schema)?.map(data=>{
    const fieldName = schema[data]?.title;
      const hasError = validateForm &&formData?.properties[fieldName]===""&& formError?.[fieldName];

    const propsComponent={
      type:schema[data]?.type,
      label:schema[data]?.title,
      labelPlacement:"outside",
      name:schema[data]?.title,
      onChange:handleChange,
      errorMessage: hasError ? formError[fieldName] : '', 
      isInvalid: !!hasError, 
      isRequired:schema[data]?.required,
      placeholder:`Enter ${schema[data]?.title}`
    }
      if(schema[data]?.type==="text"||schema[data]?.type==="number"){
        return <Input {...propsComponent}/>
      }

      if(schema[data]?.type==="password"){
        return <Input  {...propsComponent}/>
      }
    });
  }

  return (
    <div className='flex flex-col gap-4 pt-8'>
      {formRender()}
    </div>
  )
}

export default ChannelsForm