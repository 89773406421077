import React, { useState, useEffect, useRef } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Input,
  Switch,
  Textarea,
  Select,
  Slider,
  DatePicker,
  SelectItem,
  Button,
  Spinner,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Image,
} from "@nextui-org/react";
import {
  DateChanger,
  DateFormat,
  decriptedText,
} from "../../Controllers/basicControllers";
import {
  CommonCollectionFilterApi,
  CreateData,
  getOneData,
  sendUserSurveyResult,
  uploadFile,
} from "../../Controllers/strapiController";
import { Toaster } from "react-hot-toast";
import NotFound from "../../Components/ErrorHandler/NotFound";
import ServerIssue from "../../Components/ErrorHandler/ServerIssue";
import Forbbiden from "../../Components/ErrorHandler/Forbbiden";
import UnAuthorized from "../../Components/ErrorHandler/UnAuthorized";
import Titlecomponent from "../../Components/BasicComponents/Titlecomponent";
import { EyeSlashFilledIcon } from "../../Images/EyeSlashFilledIcon";
import Images from "../../Images/image.png";
import { EyeFilledIcon } from "../../Images/EyeFilledIcon";
import ButtonSpinner from "../../Components/ErrorHandler/ButtonSpinner";
import { parseDate } from "@internationalized/date";
import FunctionLoader from "../../Components/ErrorHandler/FunctionLoader";
import CustomToast from "../../Components/BasicComponents/CustomToast";
import SuccessPage from "../../Components/ErrorHandler/SuccessPage";
import SubmissionPage from "../../Components/ErrorHandler/SubmissionPage";
import ShowMoreParamsInfo from "../../Components/BasicComponents/ShowMoreParamsInfo";

const SurveyForm = ({ darkMode, surveySchema }) => {
  // STATE INITIALIZATION
  const params = useParams();
  const [searchParams] = useSearchParams();

  const queryParamsData = {
    ticketNo: searchParams.get("ticketNo") || "--",
    title: searchParams.get("title") || "--",
    description: searchParams.get("description") || "--",
    createdOn: searchParams.get("createdOn") || "--",
  };

  const [serveyForm, setServeyForm] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [serverIssue, setServerIssue] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [userAuthorization, setUserAuthorization] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [validation, setValidation] = useState();
  const [validateCondition, setValidateCondition] = useState();
  const [upload, setUpload] = useState(false);

  const fileInputRef = useRef(null);
  // FILE UPLOAD REFERENCE REF
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  // HANDLE CHANGE
  const handleChange = (field, value) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  // SUPPORTING VALUES
  const formId = decriptedText(params?._id);
  const email = decriptedText(params?._email);
  const time = params?._time;
  const datevalidate = decriptedText(time);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // PASSWORD EYE FUNCTIONALITY
  const toggleVisibility = () => setIsVisible(!isVisible);

  // API RESPONSE
  useEffect(() => {
    const id = formId ? formId : surveySchema?.attributes?.metric_id;

    if (id) {
      getOneData("survey-schemas", id)
        .then((data) => {
          if (data?.error) {
            if (data?.error?.status === 404) {
              setNotFound(true);
            } else if (data?.error?.status === 403) {
              setForbidden(true);
            } else if (data?.error?.status === 500) {
              setServerIssue(true);
            } else if (data?.error?.status === 401) {
              setAuthorized(true);
            } else {
              CustomToast(
                "Something went wrong with collections, please try after some time."
              );
            }
          } else if (data?.data) {
            setServeyForm(data?.data);
            setLoader(false);
          } else {
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          CustomToast(
            "Something went wrong with collections, please try after some time."
          );
        });
    } else {
      setLoader(false);
    }
  }, [formId, email, surveySchema]);

  useEffect(() => {
    if (datevalidate !== "" || datevalidate !== null) {
      if (!surveySchema && datevalidate) {
        const url = `filters[Timestamp][$eq]=${datevalidate}&filters[metric_id][$eq]=${formId}&filters[email][$eq]=${email}`;
        CommonCollectionFilterApi("response-jsons", url).then((data) => {
          if (data?.data?.length > 0) {
            setUserAuthorization(true);
          }
        });
      }
    }
  }, [datevalidate]);

  // Initialize form values
  useEffect(() => {
    if (serveyForm && !surveySchema) {
      const initialValues = {};
      Object.keys(serveyForm?.attributes?.JSONSchema?.properties).forEach(
        (fieldKey) => {
          const fieldConfig =
            serveyForm?.attributes?.JSONSchema?.properties[fieldKey];
          initialValues[fieldKey] = fieldConfig.defaultValue || "";
        }
      );
      setFormValues(initialValues);
    }

    if (surveySchema && serveyForm) {
      const initialValues = {};
      Object.keys(serveyForm?.attributes?.JSONSchema?.properties).forEach(
        (fieldKey) => {
          const schemaValue =
            surveySchema?.attributes?.ResponseJson?.properties?.[fieldKey];
          initialValues[fieldKey] =
            schemaValue !== undefined ? schemaValue : "";
        }
      );
      setFormValues(initialValues);
    }
  }, [serveyForm, surveySchema]);

  const UploadFileHandler = async (field, file) => {
    setUpload(true);
    const response = await uploadFile(file);
    if (response) {
      setUpload(false);
      const URLresponse = response && response[0];
      setFormValues({
        ...formValues,
        [field]: URLresponse,
      });
    }
  };

  const findRelatedFields = (schema) => {
    const relatedFields = {};

    schema &&
      Object.entries(schema)?.forEach(([key, value]) => {
        if (value?.relation) {
          relatedFields[key] = value;
        }
      });
    return relatedFields;
  };

  const removeMatchingElements = (fieldEntries, RelationEntries) => {
    const toggleFalseProperties =
      RelationEntries?.flat()?.[1]?.properties?.toggleFalse || {};
    const toggleTrueProperties =
      RelationEntries?.flat()?.[1]?.properties?.toggleTrue || {};

    // Toggle seperate data render
    const toggleFalseKeys = new Set(Object.keys(toggleFalseProperties));
    const toggleTrueKeys = new Set(Object.keys(toggleTrueProperties));

    // filter function
    const filteredArray = fieldEntries?.filter(
      (item) => !toggleFalseKeys.has(item[0]) && !toggleTrueKeys.has(item[0])
    );
    return filteredArray;
  };

  const fieldEntries = Object.entries(
    serveyForm?.attributes?.JSONSchema?.properties || {}
  );
  fieldEntries.sort((a, b) => a?.[1].id - b?.[1].id);

  const relatedData = findRelatedFields(
    serveyForm?.attributes?.JSONSchema?.properties
  );

  const RelationEntries = Object.entries(relatedData || {});

  RelationEntries.sort(([, fieldA], [, fieldB]) => fieldA.id - fieldB.id);

  const dataRendered = removeMatchingElements(fieldEntries, RelationEntries);

  // Convert the array to a single object
  const transformedObject = dataRendered.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  const renderFieldsById = () => {
    const nonRelationFields = dataRendered.filter(
      ([_, fieldConfig]) => !fieldConfig?.relation
    );
    const relationFields = dataRendered.filter(
      ([_, fieldConfig]) => fieldConfig?.relation
    );

    return (
      <>
        {nonRelationFields.map(([fieldKey, fieldConfig]) => (
          <div key={fieldKey} className="flex flex-col mb-9">
            {renderField(fieldKey, fieldConfig)}
          </div>
        ))}
        {relationFields.map(([fieldKey, fieldConfig]) => {
          return (
            <div key={fieldKey} className="flex flex-col mb-9">
              {!fieldConfig?.relation && renderField(fieldKey, fieldConfig)}
              {fieldConfig?.relation && (
                <>
                  {renderField(fieldKey, fieldConfig)}
                  {RelationEntries?.map(([key, value]) => {
                    if (key !== fieldKey) return null;

                    return (
                      <>
                        {formValues[key] === true ? (
                          <>
                            {Object.entries(value?.properties?.toggleTrue || {})
                              .sort((a, b) => a?.[1]?.id - b?.[1]?.id)
                              ?.map(([subkey, subValue]) => {
                                return (
                                  <div
                                    key={fieldKey}
                                    className="flex flex-col mb-4 pt-4"
                                  >
                                    {renderField(subkey, subValue)}
                                  </div>
                                );
                              })}
                          </>
                        ) : (
                          formValues[key] !== "" &&
                          formValues[key] === false && (
                            <>
                              {Object.entries(
                                value?.properties?.toggleFalse || {}
                              )
                                .sort((a, b) => a[1].id - b[1].id)
                                ?.map(([subkey, subValue]) => {
                                  return (
                                    <div
                                      key={fieldKey}
                                      className="flex flex-col mb-4 pt-4"
                                    >
                                      {renderField(subkey, subValue)}
                                    </div>
                                  );
                                })}
                            </>
                          )
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </>
    );
  };

  // VALIDATION FORM
  const validateField = (fieldKey, fieldConfig, value) => {
    let errorMessage = "";

    switch (fieldConfig.fieldType) {
      case "input":
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case "textarea":
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        if (fieldConfig.charLimit && value?.length > fieldConfig.charLimit) {
          errorMessage = `${fieldConfig.title} exceeds the character limit of ${fieldConfig.charLimit}.`;
        }
        break;

      case "boolean":
        if (fieldConfig.required && (value === undefined || value === "")) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case "integer":
        if (fieldConfig.required && (value === undefined || value === "")) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case "dropdown":
        if (fieldConfig.required && !value) {
          errorMessage = `Please select a value for ${fieldConfig.title}.`;
        }
        break;

      case "radio":
        if (fieldConfig.required && !value) {
          errorMessage = `Please rate a value for ${fieldConfig.title}.`;
        }
        break;

      case "date":
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        // You can add further date validations here if needed
        break;

      case "slider":
        if (fieldConfig.required && (value === undefined || value === null)) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        break;

      case "password":
        if (fieldConfig.required && !value) {
          errorMessage = `${fieldConfig.title} is required.`;
        }
        if (fieldConfig.minLength && value.length < fieldConfig.minLength) {
          errorMessage = `${fieldConfig.title} should be at least ${fieldConfig.minLength} characters long.`;
        }
        if (fieldConfig.regex && !new RegExp(fieldConfig.regex).test(value)) {
          errorMessage = `${fieldConfig.title} is not in the correct format.`;
        }
        break;

      case "file":
        if (fieldConfig.required && !value) {
          errorMessage = `Please upload a file for ${fieldConfig.title}.`;
        }
        if (
          fieldConfig.fileAccepted &&
          value &&
          fieldConfig.fileAccepted?.toLowerCase() !== value?.ext?.slice(1)
        ) {
          errorMessage = `The file type for ${fieldConfig.title} must be one of the following: ${fieldConfig.fileAccepted}.`;
        }
        break;

      default:
        break;
    }

    return errorMessage;
  };

  const validateNestedFields = (fieldConfig, formValues) => {
    let errors = {};
    for (const nestedFieldKey in fieldConfig) {
      const nestedFieldConfig = fieldConfig[nestedFieldKey];
      const nestedValue = formValues[nestedFieldKey];
      const errorMessage = validateField(
        nestedFieldKey,
        nestedFieldConfig,
        nestedValue
      );

      if (errorMessage) {
        errors[nestedFieldKey] = errorMessage;
      }
    }
    return errors;
  };

  // SUBMIT HANDLER
  const submitHandler = async () => {
    let isValid = true;
    let validationErrors = {};

    Object.keys(transformedObject).forEach((fieldKey) => {
      const fieldConfig = transformedObject[fieldKey];
      const value = formValues[fieldKey];

      // Validate top-level fields
      const errorMessage = validateField(fieldKey, fieldConfig, value);
      if (errorMessage) {
        isValid = false;
        validationErrors[fieldKey] = errorMessage;
      }

      // Check for fields with nested properties (relation fields)
      if (fieldConfig.relation === "relation" && fieldConfig.properties) {
        const toggleState = formValues[fieldKey]; // true or false
        const nestedProperties =
          fieldConfig.properties[toggleState ? "toggleTrue" : "toggleFalse"];

        const nestedErrors = validateNestedFields(nestedProperties, formValues);

        if (Object.keys(nestedErrors).length > 0) {
          isValid = false;

          // Merge each nested error directly into validationErrors
          Object.keys(nestedErrors).forEach((nestedKey) => {
            validationErrors[nestedKey] = nestedErrors[nestedKey];
          });
        }
      }
    });

    if (!isValid) {
      setValidation(validationErrors);
      setValidateCondition(true);
    } else {
      setButtonLoader(true);
      setValidateCondition(false);
      const payload = {
        ResponseJson: {
          surveyTitle: serveyForm?.attributes?.Name,
          surveyDescription: serveyForm?.attributes?.Description,
          properties: formValues,
          ...queryParamsData,
        },
        Timestamp: decriptedText(time),
        metric_id: formId,
        email: email,
      };

      const response = await CreateData("response-jsons", payload);

      const sendUserResult = await sendUserSurveyResult(payload);

      if (response) {
        setButtonLoader(false);
        CustomToast("Form submitted successfully.");
        setSubmission(true);
      }
    }
  };

  // DYNAMICALLY FORM RENDERING
  const renderField = (fieldKey, fieldConfig) => {
    const errorMessage = validation?.[fieldKey];
    const disabled = surveySchema;

    const labelprops = () => {
      return <span className={`text-sm ${disabled ? "text-default-400" : ""}`}>
        {" "}
        {fieldConfig.title}{" "}
        {fieldConfig.required && <span className="text-danger">*</span>}
        <p className="text-primary-300">
          {fieldConfig.description}
        </p>{" "}
      </span>
    }

    const commonProps = {
      isRequired: fieldConfig?.required,
      value: formValues[fieldKey] || "",
      placeholder: ` `,
      labelPlacement: "outside",
      isDisabled: disabled ? true : false,
      errorMessage:
        validateCondition &&
        formValues[fieldConfig.title] === "" &&
        errorMessage,
      isInvalid:
        validateCondition &&
        formValues[fieldConfig.title] === "" &&
        !!errorMessage,
      onChange: (e) => handleChange(fieldKey, e.target.value),
    };

    switch (fieldConfig.fieldType) {
      case "input":
        return <div>
          {labelprops()}
          <Input {...commonProps} />
        </div>
      case "textarea":
        return <div>
          {labelprops()}
          <Textarea
            {...commonProps}
            maxLength={fieldConfig.charLimit}
          />
        </div>
      case "boolean":
        return (
          <div>
            {labelprops()}
            <div className="flex items-center my-1">
              <RadioGroup
                isDisabled={disabled ? true : false}
                color="secondary"
                orientation="horizontal"
                value={
                  formValues[fieldKey] === ""
                    ? ""
                    : formValues[fieldKey]
                      ? "true"
                      : "false"
                }
                onValueChange={(value) => {
                  handleChange(fieldKey, true);
                  if (value === "true") {
                    handleChange(fieldKey, true);
                  } else if (value === "false") {
                    handleChange(fieldKey, false);
                  } else {
                    handleChange(fieldKey, "");
                  }
                }}
              >
                <Radio value="true">Yes</Radio>
                <Radio value="false">No</Radio>
              </RadioGroup>
            </div>
            {fieldConfig?.required &&
              formValues[fieldKey] === "" &&
              errorMessage !== null && (
                <p className="text-xs text-danger">{errorMessage}</p>
              )}
          </div>
        );
      case "integer":
        return <div>
          {labelprops()}
          <Input
            type="number"
            {...commonProps}
            min={fieldConfig.minValue}
            max={fieldConfig.maxValue}
          />
        </div>
      case "dropdown":
        if (fieldConfig?.multiSelect === true) {
          return (
            <div className="flex flex-col gap-3">
              {labelprops()}
              <CheckboxGroup
                size="sm"
                isRequired={fieldConfig?.required}
                isDisabled={disabled ? true : false}
                value={formValues?.[fieldKey]}
                errorMessage={
                  validateCondition &&
                  formValues[fieldConfig.title] === "" &&
                  errorMessage
                }
                isInvalid={
                  validateCondition &&
                  formValues[fieldConfig.title] === "" &&
                  !!errorMessage
                }
                onChange={(e) => handleChange(fieldKey, e)}
              >
                {fieldConfig.options.map((option) => (
                  <Checkbox value={option}>{option}</Checkbox>
                ))}
              </CheckboxGroup>
            </div>
          );
        } else {
          return (
            <div className="flex flex-col gap-3">
              {labelprops()}
              <Select
                selectionMode={fieldConfig.multiSelect ? "multiple" : "single"}
                labelPlacement="outside"
                isDisabled={disabled ? true : false}
                selectedKeys={formValues[fieldKey]?.split(",")}
                errorMessage={
                  validateCondition &&
                  formValues[fieldConfig.title] === "" &&
                  errorMessage
                }
                isInvalid={
                  validateCondition &&
                  formValues[fieldConfig.title] === "" &&
                  !!errorMessage
                }
                isRequired={fieldConfig?.required}
                placeholder={`Select option`}
                onChange={(e) => handleChange(fieldKey, e.target.value)}
              >
                {fieldConfig.options.map((option) => (
                  <SelectItem key={option} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </Select>
            </div>
          );
        }
      case "date":
        return <div>
          {labelprops()}
          <DatePicker
            labelPlacement="outside"
            isDisabled={disabled ? true : false}
            errorMessage={
              validateCondition &&
              formValues[fieldConfig.title] === "" &&
              errorMessage
            }
            isInvalid={
              validateCondition &&
              formValues[fieldConfig.title] === "" &&
              !!errorMessage
            }
            placeholder={` `}
            isRequired={fieldConfig?.required}
            value={
              formValues[fieldKey] &&
                formValues[fieldKey] !== "" &&
                formValues[fieldKey] !== "null"
                ? parseDate(DateChanger(formValues[fieldKey]))
                : null
            }
            onChange={(e) => {
              const displayDate = DateFormat(e);
              handleChange(fieldKey, displayDate);
            }}
          />
        </div>
      case "slider":
        return <div>
          {labelprops()}
          <Slider
            {...commonProps}
            max={fieldConfig.maxValue}
            min={fieldConfig.minValue}
            color="secondary"
            onChange={(value) => handleChange(fieldKey, value)}
          />
        </div>
      case "password":
        return <div>
          {labelprops()}
          <Input
            {...commonProps}
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
          />
        </div>
      case "radio":
        return <div>
          {labelprops()}
          <RadioGroup
            orientation="horizontal"
            size="sm"
            defaultValue={formValues[fieldKey] || ""}
            isRequired={fieldConfig?.required}
            isDisabled={disabled ? true : false}
            onChange={
              !surveySchema
                ? (e) => handleChange(fieldKey, e.target.value)
                : () => { }
            }
            errorMessage={
              validateCondition &&
              formValues[fieldConfig.title] === "" &&
              errorMessage
            }
            isInvalid={
              validateCondition &&
              formValues[fieldConfig.title] === "" &&
              !!errorMessage
            }
          >
            {fieldConfig?.options?.map((data, index) => {
              return (
                <Radio key={`${index}`} value={data || null}>
                  {data}
                </Radio>
              );
            })}
          </RadioGroup>
        </div>
      case "file":
        return (
          <>
            <input
              type="file"
              accept={`application/${fieldConfig.fileAccepted}`}
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                const files = e.target.files;

                if (files && files[0]) {
                  const file = files[0];
                  UploadFileHandler(fieldKey, file);
                }
              }}
            />
            <div className="flex flex-col gap-2 ">
              <p
                className={`text-sm ${disabled ? "text-default-400" : ""} ${validateCondition &&
                  fieldConfig.required &&
                  formValues[fieldConfig.title] === ""
                  ? "text-danger"
                  : "default"
                  }`}
              >
                {fieldConfig.title}
                {fieldConfig.required && <span className="text-danger">*</span>}
              </p>
              <p className="text-primary-300 text-sm">{fieldConfig?.description}</p>
              <div className="flex flex-row gap-2 items-center">
                <Button
                  isDisabled={disabled ? true : false}
                  variant="bordered"
                  size="sm"
                  onClick={handleButtonClick}
                >
                  Select file
                </Button>
                {upload ? (
                  <Spinner />
                ) : (
                  <p className="text-sm text-default-400">
                    {formValues?.[fieldConfig.title]?.name}
                  </p>
                )}
              </div>
            </div>
            {fieldConfig.fileAccepted !==
              formValues?.[fieldConfig.title]?.type?.split("/")?.[1] &&
              errorMessage !== null && (
                <p className="text-xs text-danger">{errorMessage}</p>
              )}
          </>
        );
      default:
        return null;
    }
  };

  // SUPPORTING RESPONSES
  if (notFound) {
    return <NotFound />;
  }
  if (serverIssue) {
    return <ServerIssue />;
  }
  if (forbidden) {
    return <Forbbiden />;
  }
  if (authorized) {
    return <UnAuthorized />;
  }
  if (userAuthorization) {
    return <SuccessPage />;
  }
  if (submission) {
    return <SubmissionPage />;
  }
  if (loader) {
    return <FunctionLoader darkMode={darkMode} />;
  }
  if (!surveySchema) {
    if (!emailRegex.test(email) || !datevalidate || !formId) {
      return <NotFound />;
    }
  }

  // RETURN UI
  return (
    <div
      className={`max-w-6xl mx-auto pb-8 px-4 ${!surveySchema ? "pt-8" : ""} `}
    >
      <Toaster position="bottom-right" reverseOrder={false} />
      {!surveySchema ? (
        <Image
          width={120}
          radius="none"
          className="pb-2"
          alt="NextUI"
          src={Images}
        />
      ) : (
        ""
      )}
      {!surveySchema && (
        <>
          <Titlecomponent
            title={serveyForm?.attributes?.Name}
            description={serveyForm?.attributes?.Description}
          />
          <ShowMoreParamsInfo data={queryParamsData} />
        </>
      )}
      <form className="pt-8 md:w-1/2 sm:w-full">
        <div className="flex flex-col ">{renderFieldsById()}</div>
        {!surveySchema && (
          <div className=" flex justify-end">
            <Button
              isLoading={buttonLoader}
              spinner={<ButtonSpinner />}
              color="secondary"
              variant="faded"
              onClick={() => submitHandler()}
              size="sm"
            >
              Submit
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default SurveyForm;
