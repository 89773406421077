import React, { useState, useEffect, useRef } from 'react';
import { ModalHeader, ModalBody, Input, Button, CircularProgress, Textarea } from "@nextui-org/react";
import { CreateData, uploadFile, userLoginDetails } from "../../Controllers/strapiController";
import ModalPopup from "../../Components/NextUi/ModalPopup";
import { avatarName, colorPicker, encryptedText } from '../../Controllers/basicControllers';
import ButtonComponent from '../../Components/NextUi/Button';
import AvatarComponent from '../../Components/NextUi/Avatar';
 
import {useNavigate} from "react-router-dom";
import CustomToast from '../../Components/BasicComponents/CustomToast';

const initialState = { name: '', description: '', logo: null };
const initialErrorState = { name: '', description: '', logo: null };


const CreateApp = ({ isOpen, onOpenChange,setReloader }) => {
    const [FileUpload, setFileUpload] = useState(null);
    const [file, setFile] = useState(null);
    const [imageLoader, setImageLoader] = useState(false);
    const [validate, setValidate] = useState(false);
    const fileInputRef = useRef();
    const [createForm, setCreateForm] = useState(initialState);
    const [formError, setFormError] = useState(initialErrorState);
    const userID = userLoginDetails()?.userId;
    const navigate=useNavigate();

    const commonProps = {
        isRequired: true,
        labelPlacement: 'outside',
        type: 'text',
        variant: 'faded',
        size: 'md',
        placeholder: " "
    };

    // FILE UPLOAD FUNCTION
    useEffect(() => {
        const UploadFileHandler = async () => {
            setImageLoader(true);
            const response = await uploadFile(FileUpload);
            if (response) {
                setImageLoader(false);
                const URLresponse = response && response[0];
                setFile(URLresponse);
                setCreateForm((prev) => ({ ...prev, logo: URLresponse }))
            }
        };
        if (FileUpload) {
            UploadFileHandler();
        }
    }, [FileUpload]);

    // HANDLE ONCHANGE EVENT
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateForm((prev) => ({ ...prev, [name]: value }))
    }

    // REMOVE USER PROFILE PICTURE FINCTION
    const Handleremove = async () => {
        setFile(null)
    }

    // ONCHANGE FUNCTION FOR FILE UPLOAD
    const handleFileChange = (event) => {
        const files = event.target.files;
        setImageLoader(true);
        if (files && files[0]) {
            const file = files[0];
            setFileUpload(file);
            setImageLoader(false);
        } else {
            setFileUpload(null);
            setImageLoader(false);
        }
    }

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const validation = () => {
        let isValid = true;
        const errors = {};

        if (!createForm.name) {
            isValid = false;
            errors.name = 'Name is required.';
        } else {
            errors.name = null;
        }

        if (!createForm.description) {
            isValid = false;
            errors.description = 'Description is required.';
        } else if (createForm.description.length > 200) {
            isValid = false;
            errors.description = 'Description should not exceed 200 characters.';
        } else {
            errors.description = null;
        }

        setFormError((prev) => ({ ...prev, ...errors }));
        return isValid;
    }

    const submitHandler = async (onClose) => {
        const validate = validation();

        const payload = {
            Name: createForm?.name,
            Description: createForm?.description,
            Logo:createForm?.logo,
            author: {
                disconnect: [],
                connect: [{ id: userID, position: { end: true } }]
            }
        }

        if (validate) {
            setValidate(false);
            const response = await CreateData('applications', payload);
           
            if (response.data!==null) {
                setReloader(response)
                setCreateForm(initialState);

                const id=encryptedText(`${response?.data?.id}`)
                navigate(`${id}`)
                CustomToast("Application created successfully.")
                // onClose();
            } else if (response.error) {
                CustomToast("Something went wrong. Not able to create app now. please try after some time.")
            }
        } else {
            setValidate(true);
        }
    }

    const ModalBodyData = () => {
        return <>
            <ModalHeader className="flex flex-col  gap-1">Create an application </ModalHeader>
            <ModalBody className='gap-0 h-96'>
                <div className='flex flex-col gap-4'>
                    <div className='pt-4 flex flex-col gap-4'>
                        <Input {...commonProps} label='Application Name' name="name" onChange={handleChange} errorMessage={validate && createForm.name === '' && formError.name} isInvalid={validate && createForm.name === '' && formError.name !== null} />
                        <Textarea {...commonProps} label='Description' name='description' onChange={handleChange} errorMessage={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description} isInvalid={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description !== null} />
                    </div>
                    <div className='flex flex-row items-center gap-6 pt-4'>
                        <AvatarComponent radius="full" size='sm' src={file?.url ? `${process.env.REACT_APP_STRAPI_IP_ADDRESS}${file?.url}` : ""} color={colorPicker(5)} name={avatarName(createForm.name)} />
                        <input ref={fileInputRef} type='file' onChange={(event) => handleFileChange(event)} className='hidden' />
                        <Button onClick={handleButtonClick} size='sm' variant='bordered' >Select profile image</Button>
                        {imageLoader ? <CircularProgress size="sm" aria-label="Loading..." /> : <>{file?.url && <p className='text-xs cursor-pointer' onClick={Handleremove}>Remove</p>}</>}
                    </div>
                </div>
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <ButtonComponent title="cancel" size='sm' color='none' variant="light" func={() => { onClose(); setCreateForm(initialState); }} />
            <ButtonComponent title="Create" size='sm' color='secondary' variant='faded' func={() => { submitHandler(onClose) }} />
        </>
    }

    return (
        <ModalPopup
            size="2xl"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            ModalBodyData={ModalBodyData}
            footer={footerCreate}
            scrollBehavior="inside" />
    )
}

export default CreateApp;