import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from "@nextui-org/react";
import AvatarComponent from "../../Components/NextUi/Avatar";
import { getAllData } from "../../Controllers/strapiController";
import { avatarName, StringLimit } from '../../Controllers/basicControllers';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const ChannelsList = ({ darkMode, onNext, formData, setFormData }) => {
    const [channels, setChannels] = useState();
    const [loading, setLoading] = useState(true);
    const userDetails = JSON.parse(sessionStorage.getItem("userData"))
    const userID = userDetails && userDetails.user && userDetails.user.id;

    // API RESPONSE RENDERING
    useEffect(() => {
        setLoading(true);
        getAllData("channels")
            .then((data) => { setLoading(false); setChannels(data.data) })
            .catch((error) => CustomToast("Something went wrong. Please try after sometime."))
    }, [userID])


    // SELECT CHANNEL HANDLER
    const selectHandler = (data) => {
        setFormData({channel:data});
    }

    return (
        <div className='grid pt-8 md:grid-cols-2 gap-12 sm:grid-cols-1 lg:grid-cols-3'>
            {channels && channels.map((data, index) => {
                return <div key={`${index}`} onClick={() => { selectHandler(data) }} className='w-full cursor-pointer'>
                    <Card className={`max-w-[400px] border ${formData?.channel?.id===data?.id?"border-success":"border-divider"}`} shadow='none'>
                        <CardHeader className="flex gap-3">
                            <AvatarComponent
                                alt="nextui logo"
                                height={26}
                                radius="sm"
                                size='sm'
                                name={avatarName(data?.attributes?.Name)}
                                className={data?.attributes?.Logo!== null ? "bg-background" : "default"}
                                width={26}
                                src={data?.attributes?.Logo}
                            />
                            <div className="flex flex-col">
                                <p className="text-md">{data?.attributes?.Name}</p>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div className='flex items-end justify-start gap-2 min-h-[50px]'>
                                {StringLimit(data?.attributes?.Description, 90)}
                            </div>
                        </CardBody>
                    </Card>
                </div>
            })}
        </div>
    )
}

export default ChannelsList;