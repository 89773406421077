import React from 'react';
import { Pagination } from "@nextui-org/react";

const PaginationComponent = ({ color, totalPage,currentPage,setCurrentPage,recordsPerPage }) => {
    return (
        <div>
            <Pagination
                loop
                showControls
                color={color}
                initialPage={1}
                page={currentPage}
                onChange={setCurrentPage}
                total={totalPage && Math.ceil(totalPage.length / recordsPerPage)} />
        </div>
    )
}

export default PaginationComponent