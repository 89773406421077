import React, { useState, useEffect } from 'react';
import { Avatar, Tabs, Tab } from "@nextui-org/react";
import ApplicationChannel from './ApplicationChannel';
import { useParams } from "react-router-dom";
import { avatarName, colorPicker, decriptedText } from '../../Controllers/basicControllers';
import { CommonCollectionFilterApi } from '../../Controllers/strapiController';
import AvatarComponent from '../../Components/NextUi/Avatar';
import imagerender from '../../Components/BasicComponents/imagerender';
import UserSettings from '../Settings/userSettings';
import AppSettings from './App_Settings';
import {toast} from "react-hot-toast";
import Loading from '../../Components/ErrorHandler/Loading';
import CustomToast from '../../Components/BasicComponents/CustomToast';
import ApplicationUsers from './ApplicationUsers';

const SingleApplication = ({darkMode}) => {
    const [selected, setSelected] = useState("Channels");
    const [loader,setLoader]=useState(true);
    const [reload, setReload] = useState();
    const [application,setApplication] = useState(null);
    const params = useParams();
    const appId = params?._appId;
    const singleApp=decriptedText(appId);
    const userDetails = JSON.parse(sessionStorage.getItem("userData"))
    const userID = userDetails && userDetails.user && userDetails.user.id;

    useEffect(() => {
        setLoader(true);
        const url=`filters[id][$eq]=${singleApp}&filters[$or][0][author][id][$in]=${userID}&filters[$or][1][users_permissions_users][id][$in]=${userID}&[populate][users_permissions_users][on]&[populate][author][on]`
        CommonCollectionFilterApi("applications", url)
     .then((data)=>{setApplication(data.data?.[0]);setLoader(false)})
     .catch((error)=>{
        setLoader(false);
        CustomToast("Something went wrong. Please try after sometime.");
     })
    }, [appId,reload])

    const authorization=application?.attributes?.author?.data?.id===parseInt(userID);
   
    if (loader) { return <Loading darkMode={darkMode} /> }
    
    return (
        <div>
            <div className='flex flex-row gap-4 items-center'>
                <AvatarComponent src={application?.attributes?.Logo?.data!==null?imagerender(application?.attributes?.Logo?.data?.attributes?.url):''} name={avatarName(application?.attributes?.Name)} color={colorPicker(application?.id)} radius='sm' />
                <p className='text-xl font-normal'>{application?.attributes?.Name}</p>
            </div>
            <div className='pt-8 pb-8 text-sm'>
               {application?.attributes?.Description}
            </div>
            <Tabs
                aria-label="Options"
                selectedKey={selected}
                variant={"underlined"}
                disabledKeys={authorization?[]:["Settings"]}
                color={colorPicker(application?.id)}
                onSelectionChange={setSelected}>
                <Tab key="Channels" title="Channels">
                    <ApplicationChannel application={application} darkMode={darkMode} authorization={authorization}/>
                </Tab>
                <Tab key="Users" title="Users">
                    <ApplicationUsers application={application} darkMode={darkMode} setReload={setReload} authorization={authorization}/>
                </Tab>
                <Tab key="Settings" title="Settings">
                    <AppSettings application={application} darkMode={darkMode}/>
                </Tab>
            </Tabs>
        </div>
    )
}

export default SingleApplication;