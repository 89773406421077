import React, { useState, useEffect } from 'react';
import ModalPopup from "../../Components/NextUi/ModalPopup";
import AvatarComponent from "../../Components/NextUi/Avatar";
import ButtonComponent from "../../Components/NextUi/Button";
import { ModalHeader, ModalBody, Input, Textarea, SelectItem } from "@nextui-org/react";
import { colorPicker, avatarName, encryptedText } from "../../Controllers/basicControllers";
import { CommonCollectionFilterApi, userLoginDetails, CreateData } from "../../Controllers/strapiController";
import SelectComponent from '../../Components/NextUi/Select';
 
import NotFound from '../../Components/ErrorHandler/NotFound';
import ServerIssue from '../../Components/ErrorHandler/ServerIssue';
import Forbbiden from '../../Components/ErrorHandler/Forbbiden';
import UnAuthorized from '../../Components/ErrorHandler/UnAuthorized';
import { useNavigate } from "react-router-dom";
import { validateForm } from '../../utils/rolloutValidation';
import { ErrorHandler } from '../../utils/ErrorHandler';
import CustomToast from '../../Components/BasicComponents/CustomToast';

const initialState = { name: '', description: '', appId: '', surveyId: '' };
const initialErrorState = { name: '', description: '', appId: '', surveyId: '' };

const CreateRollout = ({ isOpen, onOpenChange, setReloader }) => {

    const [createForm, setCreateForm] = useState(initialState);
    const [formError, setFormError] = useState(initialErrorState);
    const [validate, setValidate] = useState(false);
    const [application, setApplication] = useState([]);
    const [surverData, setSurverData] = useState([]);
    const [notFound, setNotFound] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [serverIssue, setServerIssue] = useState(false);
    const [authorized, setAuthorized] = useState(false);
    const userId = userLoginDetails()?.userId;
    const navigate = useNavigate();

    const commonProps = {
        isRequired: true,
        labelPlacement: 'outside',
        type: 'text',
        variant: 'faded',
        size: 'sm',
    };

    useEffect(() => {
        const url = `filters[$or][0][author][id][$in]=${userId}&filters[$or][1][users_permissions_users][id][$in]=${userId}&[populate][Logo][on]&[populate][users_permissions_users][on]`;
        CommonCollectionFilterApi("applications", url)
            .then((data) => {
                if (data?.error) {
                    ErrorHandler(data.error, { setNotFound, setForbidden, setServerIssue, setAuthorized });
                }
                else if (data?.data) {
                    setApplication(data?.data)
                }
            })
            .catch((error) => setServerIssue(true))

        const url2 = `filters[author][id][$eq]=${userId}&sort[0]=createdAt:desc&[populate][author][on]`;

        CommonCollectionFilterApi('survey-schemas', url2)
            .then((data) => setSurverData(data?.data))
            .catch((error) => setServerIssue(true))
    }, [])

    // HANDLE ONCHANGE EVENT
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCreateForm((prev) => ({ ...prev, [name]: value }))
    }

    const selectItemsComponent = () => {
        return application?.map((data, index) => {
            return <SelectItem key={`${data?.id}`} value={data?.id}>{data?.attributes?.Name}</SelectItem>
        })
    }

    const SelectSurvey = () => {
        return surverData?.map((data, index) => {
            return <SelectItem key={`${data?.id}`} value={data?.id}>{data?.attributes?.Name}</SelectItem>
        })
    }

    const submitHandler = async (onClose) => {

        const { isValid, errors } = validateForm(createForm);
        setFormError(errors);
        const payload = {
            Name: createForm?.name,
            Description: createForm?.description,
            app_id: createForm?.appId,
            author: {
                disconnect: [],
                connect: [{ id: userId, position: { end: true } }]
            },
            survey_schema: {
                disconnect: [],
                connect: [{ id: parseInt(createForm?.surveyId), position: { end: true } }]
            }
        }

        if (isValid) {
            setValidate(false);
            const response = await CreateData('rollouts', payload);
            if (response?.data) {
                setReloader(response)
                setCreateForm(initialState);
                const id = encryptedText(`${response?.data?.id}`);
                navigate(`${id}`);
            } else if (response.error) {
                CustomToast("Something went wrong. Not able to create app now. please try after some time.")
            }
        } else {
            setValidate(true);
        }
    }

    const ModalBodyData = () => {
        return <>
            <ModalHeader className="flex flex-col  gap-1">Create an Rollout </ModalHeader>
            <ModalBody className='gap-0 h-96'>
                <div className='flex flex-col gap-4'>
                    <div className='pt-4 flex flex-col gap-4 pb-4'>
                        <div className='flex flex-row gap-4 justify-center items-center'>
                            <AvatarComponent radius="full" size='md' src="" color={colorPicker(5)} name={avatarName(createForm.name)} className="h-10 w-11" />
                            <Input placeholder=" " className="max-w-full" {...commonProps} label='Rollout Name' name="name" onChange={handleChange} errorMessage={validate && createForm.name === '' && formError.name} isInvalid={validate && createForm.name === '' && formError.name !== null} />
                        </div>
                        <Textarea {...commonProps} label='Description' name='description' onChange={handleChange} errorMessage={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description} isInvalid={validate && (createForm.description === '' || createForm.description.length > 200) && formError.description !== null} />
                        <SelectComponent
                            commonProps={commonProps}
                            placeholder="Select the app"
                            selectItems={selectItemsComponent}
                            label="Application"
                            handleChange={handleChange}
                            errorMessage={validate && createForm.appId === '' && formError.appId}
                            isInvalid={validate && createForm.appId === '' && formError.appId !== null}
                            defaultSelectedKeys={[]}
                            name="appId" />
                        <SelectComponent
                            commonProps={commonProps}
                            placeholder="Select the survey"
                            selectItems={SelectSurvey}
                            label="Survey"
                            handleChange={handleChange}
                            errorMessage={validate && createForm.surveyId === '' && formError.surveyId}
                            isInvalid={validate && createForm.surveyId === '' && formError.surveyId !== null}
                            defaultSelectedKeys={[]}
                            name="surveyId" />
                    </div>
                </div>
            </ModalBody>
        </>
    }

    const footerCreate = (onClose) => {
        return <>
            <ButtonComponent title="cancel" size='sm' color='none' variant="light" func={() => { onClose(); setCreateForm(initialState); }} />
            <ButtonComponent title="Create" size='sm' color='secondary' variant='faded' func={() => { submitHandler(onClose) }} />
        </>
    }

    if (notFound) return <NotFound />;
    if (serverIssue) return <ServerIssue />;
    if (forbidden) return <Forbbiden />;
    if (authorized) return <UnAuthorized />;

    return (
        <div>
            <ModalPopup
                size="xl"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                ModalBodyData={ModalBodyData}
                footer={footerCreate}
                scrollBehavior="inside" />
        </div>
    )
}

export default CreateRollout;