export const userLoginDetails = () => {
  const userDetails = JSON.parse(sessionStorage.getItem("userData"));
  const userId = userDetails && userDetails.user && userDetails.user.id;
  return { userId, userDetails };
};

// CREATE COLLECTIONS DATA
export const CreateData = async (CollectionName, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/create`;
  const payload = {
    collection_name: CollectionName,
    newData: newData,
  };

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();
  return data;
};

// UPDATE COLLECTIONS DATA
export const UpdateData = async (CollectionName, id, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/update`;
  const payload = {
    collection_name: CollectionName,
    id: id,
    newData: newData,
  };

  const response = await fetch(URL, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  const data = await response.json();

  return data;
};

// GET COLLECTION DATA
export const getOneData = async (CollectionName, id) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/get`;
  const payload = {
    collection_name: CollectionName,
    id: id,
  };

  const response = await fetch(URL, {
    method: "POST", // Changed to POST because GET requests with a body are not standard
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  const data = await response.json();
  return data;
};

// UPDATE FOR SIGLE USER DETAILS
export const UpdateOneUserData = async (collectionName, id, newData) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/update`;
  const payload = {
    collection_name: collectionName,
    id: id,
    newData: newData,
  };

  const response = await fetch(URL, {
    method: "POST", // Using POST here due to the need to send a body
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  const updatedData = await response.json();
  return updatedData;
};

// GET ALL COLLECTIONS DATA
export const getAllData = async (collectionName) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/getAll`;
  const payload = {
    collection_name: collectionName,
  };

  const response = await fetch(URL, {
    method: "POST", // Changed to POST for middleware
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  const data = await response.json();
  return data;
};

// Continue from here.

// DELETE ATTRIBUTES INSIDE COLLECTIONS
export const DeleteSingleAttribute = async (collectionName, id) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/deleteSingleAttribute`;
  const payload = {
    collectionName,
    id,
  };

  const response = await fetch(URL, {
    method: "POST", // Use POST to send data to middleware
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  const data = await response.json();
  return data;
};

// FILE UPLOAD TO STRAPI
export const uploadFile = async (file) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/uploadFile`;

  const formData = new FormData();
  formData.append("file", file);

  const response = await fetch(URL, {
    method: "POST",
    body: formData,
  });

  const data = await response.json();
  return data;
};

// ROLES APIs * inactive *
export const getAllRoles = async () => {
  let URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/users-permissions/roles`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json().then((role) => {
    return role.roles;
  });

  const FilterData = data && data.find((role) => role.name === "Public");

  return FilterData;
};

// GET ALL TEAMS DATA *inactive*
export const getAllCollectionData = async (collectionName) => {
  var URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collectionName}?[populate][Logo][on]&[populate][members][on]&&populate[members][populate][Picture][fields][0]=name&populate[members][populate][Picture][fields][1]=alternativeText&populate[members][populate][Picture][fields][2]=caption&populate[members][populate][Picture][fields][3]=url`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json();

  return data;
};

// GET ALL USER DETAILS DATA
export const getAllDataUsers = async (collections, Userid) => {
  var URL = `${process.env.REACT_APP_STRAPI_IP_ADDRESS}/api/${collections}?filters[author][id][$eq]=${Userid}&[populate][author][on]`;

  const response = await fetch(URL, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = await response.json();

  return data;
};

// EMAIL TO INVITE PEOPLE
export const InviteUser = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-survey`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = response.json();

  return data;
};

export const sendInvite = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-invite`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = response.json();

  return data;
};

// SINGLE COLLECTION COMMON FILTER URL *inactive*?
export const singleCollectionFilter = async (collection, collectionId, url) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/filter-single-collection`;

  const payload = {
    collection,
    collectionId,
    url,
  };

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  const data = await response.json();
  return data;
};

// COMMON COLLECTION URL FILTER FOR ALL COLLECTIONS
export const CommonCollectionFilterApi = async (collection, url) => {
  const URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/filter-collection`;

  const payload = {
    collection,
    url,
  };

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  const data = await response.json();
  return data;
};

// OTP CREATE
export const sendCreateAccountOTP = async (payload) => {
  var URL = `${process.env.REACT_APP_MIDDLEWARE_IP_ADDRESS}/send-create-otp`;

  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    redirect: "follow",
    headers: {
      // Authorization: `Bearer ${jwt}`,
      "Content-Type": "application/json", // Specify the content type as JSON
    },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(payload),
  });

  let data = await response.json();

  return data;
};

//Send Survey From to https://n8n.nanoheal.work/webhook/user-survey-result

export const sendUserSurveyResult = async (newData) => {
  const URL = `${process.env.REACT_APP_WEBHOOK_API}/user-survey-result`;

  console.log(URL);
  
  const credentials = btoa(`ApiIntegration:__n8n_BLANK_VALUE_e5362baf-c777-4d57-a609-6eaf1f9e87f6`);
  const response = await fetch(URL, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Basic ${credentials}`,
    },

    body: JSON.stringify(newData),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });

  let data = response.json();
  return data;
};
